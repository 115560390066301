@import 'src/variables';
@import 'src/app/component-library-wave/designtokens';

.container {
  @media (max-width: $screen-md) {
    position: fixed;
    left: 0;
    z-index: 10;
    width: 100%;
    background-color: $neutrals-white;
    border-top: 1px solid $secondary-200-tint;
  }
}
