@import 'variables';

.pageContainer {
  padding-left: $mobile-edge;
  padding-right: $mobile-edge;

  .testAccountMessage {
    margin-bottom: $spacing * 2;
  }

  .pageHeading {
    margin-bottom: $spacing;
  }

  .paragraph {
    margin-bottom: $spacing * 2;
    @media (min-width: $screen-md) {
      margin-bottom: $spacing * 4;
    }
  }

  .amountLimitInput {
    margin-bottom: $spacing * 2;
  }

  .eFakturaHeading {
    margin-bottom: $spacing;
  }

  .eFakturaInformation {
    margin-bottom: 60px;
    @media (min-width: $screen-md) {
      margin-bottom: $spacing * 7;
    }
  }

  .customerHasAvtaleGiro {
    margin-bottom: $spacing * 4;
  }
}

.avtaleGiroButtonContinue {
  margin-bottom: $spacing * 4;
  @media (min-width: $screen-md) {
    margin-bottom: $spacing * 5;
  }
}
