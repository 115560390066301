@import 'src/variables';
@import 'src/app/component-library-wave/designtokens';

.desktopTwoColumns {
  display: grid;
  margin-bottom: $spacing * 4;
  grid-template-areas:
    'head head'
    'picture picture'
    'text text';
  grid-template-columns: 1fr 1fr;
  @media (min-width: $screen-md) {
    grid-template-areas:
      'head picture'
      'text picture';
    grid-template-columns: [col1-start] 50% [col3-end] 50%;
    margin-left: $spacing * 4;
  }
}

.imageContainer {
  padding: $spacing * 2;
  border: 1px solid $formds-neutrals-tint-200;
  border-radius: $border-radius;
  display: flex;
  @media (min-width: $screen-md) {
    border: 0;
    padding: 0;
  }
}

.imageColumn {
  grid-area: picture;
  align-items: center;
}

.heading {
  grid-area: head;
}

.textColumn {
  grid-area: text;
  place-self: start;
}

.pageTitle {
  margin-bottom: $spacing * 2;
  margin-top: $spacing * 2;
  font-size: $headline-xxs-font-size;
  @media (min-width: $screen-md) {
    font-size: $headline1-sm-font-size;
    margin-bottom: 0;
    margin-top: 0;
  }
}

.titleTwoBox {
  font-size: $headline4-sm-font-size;
  @media (min-width: $screen-md) {
    font-size: $paragraph-sm-line-height;
  }
}

.deviceName {
  margin-top: $spacing;
  margin-bottom: $spacing * 2;
  font-size: $headline6-md-font-size;
  @media (min-width: $screen-md) {
    margin-top: 0;
  }
}

.moreInfoLink {
  border-bottom: solid;
  text-decoration: none;
}

.linkText {
  font-size: $paragraph-md-font-size;
}

.deviceInfo {
  margin-bottom: $spacing * 2;
  font-size: $uiText-md-font-size;
  line-height: $spacing * 3;
  @media (min-width: $screen-md) {
    font-size: $headline6-md-font-size;
    line-height: $spacing * 4;
  }
}

.hgwLinkButton {
  margin-bottom: $spacing * 2;
  @media (min-width: $screen-md) {
    margin-bottom: $spacing;
  }
}
