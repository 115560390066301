@import 'src/variables';
@import 'src/app/component-library-wave/designtokens';

.container {
  display: grid;
  border: 1px solid $secondary200;
  box-sizing: border-box;
  border-radius: $spacing;
  padding: $spacing * 2 $spacing * 4;
  text-align: left;
  list-style-type: none;
  align-items: center;
  width: 100%;
  column-gap: $spacing;
  grid-template-areas:
    'name arrow'
    'description arrow'
    'helper helper';
  @media (min-width: $screen-md) {
    grid-template-columns: 7fr 10fr auto;
    grid-template-areas:
      'name description arrow'
      'helper helper helper';
    padding: $spacing * 4;
  }
}

.noArrow {
  grid-template-areas:
    'name'
    'description'
    'helper';
  @media (min-width: $screen-md) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'name description'
      'helper helper';
  }
}

.arrow {
  display: flex;
  grid-area: arrow;
  cursor: pointer;
  justify-content: flex-end;
}

.headingContainer {
  display: flex;
  align-items: center;
}

.menuText {
  cursor: pointer;
  flex-shrink: 1;
  padding-right: $spacing * 4;
}

.helpToggle {
  grid-area: helper;
  flex-grow: 100;

  > div {
    margin: 0;
  }
}

.helpText {
  margin-top: $spacing * 2;
  cursor: pointer;
  grid-area: helper;
}

.button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  text-align: left;
  @media (min-width: $screen-md) {
    text-align: right;
  }
}

.description {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: $spacing;
  grid-area: description;
  @media (min-width: $screen-md) {
    margin-top: 0;
    justify-content: flex-end;
  }
}
