@import 'variables';

.panel {
  background-color: #fff;
  border: 1px solid #f4f4f4;
  border-radius: $border-radius;
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
  cursor: pointer;
  padding: ($spacing * 3);
  width: 100%;

  &:hover {
    background-color: $secondary050;
  }
}

// Large screens
@media (min-width: $screen-md) {
  .panel {
    padding: ($spacing * 3) ($spacing * 2);
    width: $grid-narrow;
  }
}
