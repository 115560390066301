@import 'variables';

.pageContainer {
  padding-left: $mobile-edge;
  padding-right: $mobile-edge;

  .pageHeading {
    margin-bottom: $spacing * 2;
  }

  .pageBody {
    margin-bottom: $spacing * 5;
    @media (min-width: $screen-md) {
      margin-bottom: $spacing * 4;
    }
  }

  .customerServiceHeading {
    margin-bottom: $spacing;
  }

  .customerServiceBody {
    margin-bottom: $spacing * 4;
  }
}
