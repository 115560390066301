$font-size-8: 3rem; // 48px
$font-size-7: 2.375rem; // 38px
$font-size-6: 2rem; // 32px
$font-size-5: 1.75rem; // 28px
$font-size-4: 1.5rem; // 24px
$font-size-3: 1.375rem; // 22px
$font-size-2: 1.25rem; // 20px
$font-size-1: 1.125rem; // 18px
$font-size-0: 1rem; // 16px

$line-height-9: 4.5rem;
$line-height-8: 3.5625rem;
$line-height-7: 3rem;
$line-height-6: 2.625rem;
$line-height-5: 2.25rem;
$line-height-4: 2.063rem;
$line-height-3: 2rem;
$line-height-2: 1.875rem;
$line-height-1: 1.688rem;
$line-height-0: 1.5rem;

$font-walsheim-weight-2: 700;
$font-walsheim-weight-1: 500;
$font-walsheim-weight-0: 400;

$font-roboto-weight-2: 800;
$font-roboto-weight-0: 400;

$letter-spacing-4: 0.038rem;
$letter-spacing-3: 0.0331rem;
$letter-spacing-2: 0.025rem;
$letter-spacing-1: 0.019rem;
$letter-spacing-0: 0.013rem;

$paragraph-spacing-9: 96;
$paragraph-spacing-8: 76;
$paragraph-spacing-7: 64;
$paragraph-spacing-6: 56;
$paragraph-spacing-2: 48;
$paragraph-spacing-4: 44;
$paragraph-spacing-3: 40;
$paragraph-spacing-2: 36;
$paragraph-spacing-1: 32;

$font-family-gt-walsheim: 'GT Walsheim Pro', 'sans-serif';
$font-family-roboto: 'Roboto', 'sans-serif';

$neutrals-050-tint: #f6f6f6;
$secondary-200-tint: #d9d6d7;
