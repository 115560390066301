@import 'variables';

.searchContainer {
  .label {
    margin-bottom: $spacing;
    display: block;
  }

  .field {
    display: flex;
    align-items: center;
    border: 1px solid $neutral-300;
    border-radius: 2px;
    box-shadow: $box-shadow;
    padding: 14px ($spacing * 2);

    .icon {
      margin-right: ($spacing * 2);
      width: 1.5rem;
    }

    .input {
      font-size: 1.25rem;
      border: 0;
      padding-left: 0;
      width: 100%;

      &::placeholder {
        color: $neutrals-800-shade;
        font-style: italic;
      }

      &:focus-visible {
        outline: 0;
      }
    }

    &.focus {
      box-shadow:
        -3px -3px 0 0 $guidance-500-core,
        3px -3px 0 0 $guidance-500-core,
        -3px 3px 0 0 $guidance-500-core,
        3px 3px 0 0 $guidance-500-core;
      outline: auto;
    }
  }

  .results {
    width: 100%;

    .list {
      background-color: $white;
      border: 1px solid $medium-grey;
      border-radius: 0.5rem;
      box-shadow: 0 0.25rem 0.25rem rgb(0 0 0 / 25%);
      height: 12.5rem;
      list-style: none;
      margin: 0 auto;
      padding: 0;
      overflow-y: scroll;

      .item {
        font-size: 1.125rem;
        padding: 1rem;

        &:hover,
        &:active,
        &:focus {
          background-color: $red;
          color: $white;
          cursor: pointer;
        }
      }

      .itemFocus {
        background-color: $red;
        color: $white;
      }
    }
  }
}

// Large screens
@media (min-width: $screen-md) {
  .searchContainer {
    margin: 0;

    .label {
      margin-bottom: $spacing * 2;
    }
  }
}
