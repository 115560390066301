@import '/src/app/component-library-wave/designtokens.scss';
.helpText {
  margin-top: 2px;
  margin-left: $spacing;
  color: $neutrals-800-shade;
  &.disabled {
    color: $neutrals-300-tint;
  }
}
.helpTextContainer {
  display: flex;
  align-items: center;

  .iconContainer {
    display: flex;
    align-items: center;
  }
}
