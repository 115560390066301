@import 'variables';
@import 'src/app/component-library-wave/designtokens';

.awoPage {
  margin: 0 $mobile-edge;

  @media (min-width: $screen-md) {
    margin: 0 $spacing * 4;
  }
}

.awoIntroContainer {
  width: 100%;
  margin-bottom: $spacing * 4;

  @media (min-width: $screen-md) {
    margin-bottom: 0;
  }
}

.pageHeading {
  margin-bottom: $spacing * 2;
}

.pageHeadingMobile {
  @media (min-width: $screen-md) {
    display: none;
  }
}

.pageHeadingDesktop {
  display: none;

  @media (min-width: $screen-md) {
    display: block;
  }
}

.desktopColumns {
  @media (min-width: $screen-md) {
    display: flex;
    align-items: center;

    @media (min-width: $screen-md) {
      margin-bottom: 2 * $spacing;
      gap: $spacing * 2;
    }
  }
}

.imageColumn {
  border: $border;
  border-radius: $border-radius;
  margin-bottom: $spacing * 2;

  @media (min-width: $screen-md) {
    border: 0;
    width: 35%;
    margin-bottom: 0;
  }
}

.hwgImage {
  display: block;
  width: 100%;
  margin: 0 auto;

  @media (min-width: $screen-md) {
    width: 85%;
  }
}

.meshExplaination {
  margin-bottom: $spacing * 2;

  @media (min-width: $screen-md) {
    max-width: 475px;
  }
}

.link,
.linkText {
  font-weight: bold;
  border-bottom: solid;
  text-decoration: none;
}

.awoWifiHeading {
  margin: $spacing * 2 $spacing * 3;
  border-bottom: $border;
  padding-bottom: $spacing;
  font-family: $paragraph-l-font-family;
  @media (min-width: $screen-md) {
    margin-top: $spacing * 4;
    padding-bottom: $spacing * 2;
    font-family: $hero-s-font-family;
  }
}

.awoWifiSettings {
  border: $border;
  border-radius: $border-radius;
  padding-left: $spacing;
  padding-right: $spacing;
}

.awoNetworkDevicesHeading {
  margin: $spacing * 4 0 $spacing * 2;
  @media (min-width: $screen-md) {
    margin: $spacing * 7 0 $spacing * 4 $spacing * 4;
  }
}

.awoNetworkDevices {
  border: $border;
  border-radius: $border-radius;
  padding: $spacing * 3 $spacing * 4;
  margin-bottom: $spacing * 4;

  @media (min-width: $screen-md) {
    margin-bottom: $spacing * 7;
  }
}

.toast {
  margin-bottom: $spacing * 2;
  @media (min-width: $screen-md) {
    margin-bottom: $spacing * 3;
  }
}

.deviceContainer {
  margin-top: $spacing * 5;
  margin-bottom: 100px;
  @media (min-width: $screen-md) {
    margin-top: $spacing * 8;
  }
}
