@import 'src/variables';
@import 'src/app/component-library-wave/designtokens';

.bandFrequencyHelp {
  padding-bottom: $spacing * 2;
}

.bandFrequencyHelpText {
  padding-top: $spacing * 2;
  font-size: $uiText-md-font-size;
}

.bandToggle {
  > div {
    padding: 0;
  }

  > div > label {
    margin-left: 0;
    font-size: $headline6-md-font-size;
    line-height: $headline-xxs-line-height;
    font-family: $text-xxs-medium-font-family;
    font-weight: $headline-xxs-font-weight;
    @media (min-width: $screen-md) {
      font-size: $headline-xxs-font-size;
    }
  }
}
