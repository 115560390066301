@import 'variables';
@import 'src/app/component-library-wave/designtokens';

.greeting {
  margin-bottom: $spacing * 5;
  text-align: center;

  @media (min-width: $screen-m) {
    margin-top: $spacing * 4;
  }
}

.heading1 {
  text-align: center;
  margin-top: 7 * $spacing;
  margin-bottom: 6 * $spacing;
  @media (min-width: $screen-md) {
    margin-bottom: 5 * $spacing;
  }
}

.heading2 {
  margin-bottom: $spacing * 4;
  margin-top: $spacing * 9;
  margin-left: $spacing * 2;
  @media (min-width: $screen-md) {
    margin-left: 0;
  }
}

.ingress {
  margin-bottom: $spacing * 3;
}
