@import 'variables';
@import 'src/app/component-library-wave/designtokens';

$screen-custom: 700px;

.container {
  margin: 0 $mobile-edge $mobile-edge * 3;
  padding-bottom: $spacing * 3;

  @media (min-width: $screen-m) {
    margin: 0 auto $mobile-edge * 6;
  }

  .usage {
    padding-top: $spacing;
  }

  .disclaimer {
    margin-top: $spacing * 2;
    margin-bottom: $spacing * 4;
    max-width: 640px;
  }

  .periodFilter {
    margin-bottom: $spacing * 4;
  }

  .entry,
  .details {
    display: flex;
    gap: $spacing * 2 $spacing;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  .entry:nth-child(2n + 1) {
    background-color: $neutrals-050-tint;
  }

  .empty {
    padding: $spacing * 3;
    background-color: $neutrals-050-tint;
  }

  .date {
    flex-basis: 100%;
    @media (min-width: $screen-custom) {
      flex-basis: 20%;
    }
  }

  .section {
    flex-basis: 45%;
    @media (min-width: $screen-custom) {
      flex-basis: 18%;
    }
  }

  .details {
    flex-basis: 100%;
    border-radius: $border-radius;
    margin: $spacing * 3;
    justify-content: space-between;
  }

  .description {
    font-weight: 400;
  }
}
