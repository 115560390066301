@import 'variables';

.container {
  position: relative;
  margin-left: $spacing / 2;

  .button {
    border: none;
    background: transparent;
    cursor: pointer;
    width: 24px;
  }

  .copyIcon {
    position: absolute;
    top: 0;
    left: 0;
    stroke-dasharray: 50;
    stroke-dashoffset: 0;
    transition: all 300ms ease-in-out;
  }

  .checkIcon {
    position: absolute;
    top: 0;
    left: 0;
    stroke-dasharray: 50;
    stroke-dashoffset: 0;
    transition: all 300ms ease-in-out;
  }

  .hidden {
    opacity: 0;
  }
}
