@import 'variables';

.milestone {
  margin-bottom: $spacing * 8;
  text-align: center;
  z-index: 1;

  @media (min-width: $screen-md) {
    margin-bottom: auto;
  }

  &.highlight {
    @media (min-width: $screen-md) {
      margin-top: auto;
    }
  }

  .button {
    align-items: center;
    background-color: transparent;
    border: none;
    color: rgba($secondary-900, 0.4);
    cursor: pointer;
    display: flex;
    font-weight: bold;
    line-height: #{calc(26 / $base-font-size)}rem;
    margin-left: $spacing * 4;
    padding: 0;
    width: 72px;

    @media (min-width: $screen-md) {
      flex-direction: column;
      margin-left: 0;
      margin-top: $spacing * 4;
    }

    &.currentStep {
      color: $secondary-900;
      font-size: #{calc(20 / $base-font-size)}rem;
      margin-bottom: 4px;
      margin-left: 0;
      margin-top: 0;
      width: $spacing * 17;
    }

    .icon {
      margin-right: 40px;

      @media (min-width: $screen-md) {
        margin-right: 0;
        margin-bottom: $spacing * 2;
      }

      &.currentStep {
        margin-right: $spacing;
        margin-bottom: $spacing;

        @media (min-width: $screen-md) {
          margin-right: 0;
          margin-top: 0;
        }
      }
    }

    .label {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      text-align: left;

      @media (min-width: $screen-md) {
        text-align: center;
      }

      .name {
        min-width: calc(100% - 16px);
      }

      .position {
        font-size: #{calc(16 / $base-font-size)}rem;
        font-weight: normal;
        line-height: #{calc(20.8 / $base-font-size)}rem;
      }
    }
  }

  .arrow {
    display: block;
    margin-bottom: -1px;
    margin-left: auto;
    margin-right: $spacing * 8;
    width: 28px;

    &.step1ArrowColor {
      fill: #d2b2bb;
    }

    &.step2ArrowColor {
      fill: #fae5c1;
    }

    &.step3ArrowColor {
      fill: #cbdae3;
    }

    &.step4ArrowColor {
      fill: #f2b8b5;
    }

    &.step5ArrowColor {
      fill: #b9d4ab;
    }

    @media (min-width: $screen-md) {
      margin-right: auto;
      margin-top: $spacing * 8.5;
    }

    &.currentStep {
      @media (min-width: $screen-md) {
        margin-top: $spacing * 3;
      }
    }
  }

  &:last-child {
    margin-bottom: 0;

    .button {
      &.currentStep {
        margin-bottom: -$spacing * 1.5;

        @media (min-width: $screen-md) {
          margin-top: auto;
        }
      }
    }
  }

  &:first-child {
    .button {
      &.currentStep {
        margin-top: -$spacing * 1.5;

        @media (min-width: $screen-md) {
          margin-top: auto;
        }
      }
    }
  }
}
