@import 'variables';

.invoiceNumber {
  font-weight: $formds-ui-s-font-weight;
  font-size: $formds-ui-s-font-size;
}

.container {
  @media (min-width: $screen-md) {
    border: none;
  }
  margin-bottom: $spacing * 2;
}
