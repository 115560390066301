@import 'variables';

.container {
  padding: 0 $mobile-edge;
}

.messageContainer {
  padding: $spacing * 4 0;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing * 2;

  @media (min-width: $screen-md) {
    flex-direction: row;
  }
}

.link {
  display: inline-block;
  padding-top: $spacing * 4;
}
