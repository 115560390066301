@import '/src/app/component-library-wave/designtokens.scss';

.heading {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;
  padding: ($spacing * 2);
  width: 100%;

  background: none;
  border: 0;
  text-align: left;

  &.clickable {
    &:hover {
      cursor: pointer;
    }
  }
}
