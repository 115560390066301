@import 'src/variables';

.container {
  padding: 0 $mobile-edge;
  @media (min-width: $screen-md) {
    margin: 0 auto ($spacing * 7);
    max-width: $grid-wide;
  }

  .heading {
    margin-bottom: 4 * $spacing;
    @media (min-width: $screen-md) {
      margin-bottom: 5 * $spacing;
      text-align: center;
    }
  }

  .leadHeading {
    margin-bottom: 2 * $spacing;
  }

  .lead {
    margin-bottom: 43px;
    @media (min-width: $screen-md) {
      margin-bottom: 57px;
    }
  }

  .toggleSwitch {
    display: block;
    margin-bottom: 15px;
  }

  .myprofilelink {
    color: $link;
    font-weight: bold;
  }

  .options {
    margin-bottom: 9 * $spacing;
  }
}
