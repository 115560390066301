@import 'variables';
@import 'src/app/component-library-wave/designtokens';

.alert {
  margin-bottom: $spacing * 2;
}

.flexContainer {
  display: flex;
  flex-direction: column;
}

@media (max-width: $screen-m - 1) {
  .column:not(:last-child) {
    margin-bottom: $spacing * 3;
  }
}

.columnHeader {
  margin-bottom: $spacing;
}

.description {
  margin-bottom: $spacing * 3;
}

// Tablet+
@media (min-width: $screen-s) {
  .flexContainer {
    flex-direction: row;
  }

  .column:not(:last-child) {
    margin-right: $spacing * 4;
  }
}
