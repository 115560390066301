@import 'variables';
@import 'src/app/component-library-wave/designtokens';

.datepicker {
  border: none;
  padding: 0;

  .heading {
    margin-bottom: $spacing * 2;
  }

  .inputGroup {
    display: flex;
    margin-bottom: $spacing * 2;
    flex-wrap: wrap;
    row-gap: $spacing * 2;

    .day,
    .month {
      padding-right: $spacing * 2;
      flex-basis: 33%;
      min-width: 3.5rem;

      @media (min-width: $screen-md) {
        padding-right: $spacing * 4;
      }
    }

    .year {
      flex-basis: 34%;
      min-width: 4.3rem;

      &.maxBreakpointDesktop {
        @media (min-width: $screen-m) {
          min-width: $spacing * 10;
        }
      }
    }
  }

  .errors {
    color: $secondary-900;
    background-color: $primary-100;
    border: 1px solid $primary-500;
    border-radius: calc($spacing / 4);
    padding: $spacing * 3;

    .error {
      margin-bottom: $spacing * 2;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
