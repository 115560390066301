@import 'src/variables';
@import '/src/app/component-library-wave/designtokens';

.container {
  text-align: center;
}

.title {
  margin-bottom: $spacing * 4;
}

.label {
  text-align: left;
  word-break: break-all;
}

.invalidValue {
  color: $primary-500;
}

.userDetail {
  padding: 0;
  margin-left: $spacing;
  margin-right: $spacing;
  margin-bottom: $spacing * 2;
}

.buttons {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  align-items: stretch;

  button {
    margin: $spacing * 3 $spacing * 3 0 $spacing * 3;
  }
}

.buttonsNarrow {
  align-items: center;
}

.variable {
  margin-left: $spacing;
  word-break: break-word;

  @media (min-width: $grid-narrow) {
    word-break: inherit;
  }
}
