@import 'src/variables';
@import 'src/app/component-library-wave/designtokens';

.userForm {
  margin: 0 $mobile-edge;

  .roleInfo {
    @media (min-width: $screen-sm) {
      padding-bottom: $spacing * 2;
    }
  }

  .buttonBar {
    display: flex;
    column-gap: $spacing;
    padding: $spacing ($spacing * 2) 0;
    justify-content: flex-end;
    width: 100%;

    button:last-child {
      margin-right: 0;
    }

    @media (min-width: $screen-sm) {
      padding: ($spacing * 2) 0 0;
    }

    @media (min-width: $screen-md) {
      padding-bottom: $spacing * 15;
    }
  }

  .fieldSection {
    display: flex;
    flex-direction: column;
    row-gap: ($spacing * 2);
    padding: ($spacing * 3) 0;
    @media (max-width: $screen-sm) {
      padding: $spacing * 2 0 $spacing * 4 0;

      &:first-of-type {
        padding-top: 0;
      }

      &:last-of-type {
        padding-bottom: 0;
      }
    }
  }

  .fieldRow {
    display: flex;
    flex-direction: column;
    row-gap: ($spacing * 2);

    @media (min-width: $screen-sm) {
      margin-bottom: $spacing * 2;
      flex-direction: row;
      column-gap: ($spacing * 3);
    }
  }

  // RadioButton and Datepicker components use different font-size and line-height compared to Input component
  .contactMethod > legend,
  .dateOfBirth legend {
    margin-bottom: $spacing;

    @media (min-width: $screen-s) {
      font-size: $text-m-regular-font-size;
      line-height: $text-m-regular-line-height;
    }
    @media (max-width: $screen-sm) {
      font-size: $text-s-regular-font-size;
      line-height: $text-s-regular-line-height;
    }
  }

  .dateOfBirth {
    margin-bottom: $spacing * 2;

    .datepicker {
      max-width: 18.125rem;
    }
  }

  .firstName,
  .lastName,
  .phoneInput {
    flex: 1 1 0;
  }
}

.iconMessage {
  align-items: flex-start;
  margin-top: $spacing * 2;
}

.alert {
  margin: ($spacing * 2) ($spacing * 2) 0;

  @media (min-width: $screen-sm) {
    margin: ($spacing * 3) 0 0;
  }
}

.fullName {
  margin-bottom: $spacing * 2;
}
