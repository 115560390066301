@import 'variables';
@import 'src/app/component-library-wave/designtokens';

.container {
  display: flex;
  flex-direction: column;
}

.entry {
  padding: $spacing * 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  @media (min-width: $screen-md) {
    align-items: center;
    flex-direction: row;
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid $formds-common-black-180;
  }
}

.description {
  display: flex;
  align-items: flex-start;
}
