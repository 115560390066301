@import 'src/variables';

.group {
  margin-bottom: 43px;
  @media (min-width: $screen-md) {
    margin-bottom: 57px;
  }

  .heading {
    margin-bottom: 2 * $spacing;
  }
}

.subscriptionItems {
  padding: 0;
}
