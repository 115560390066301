@import 'variables';

.customerDiggingStatus {
  display: flex;
  flex-direction: column;
  margin-bottom: $spacing * 5;

  .confirmationText {
    border: 1px solid $secondary-400;
    border-radius: $border-radius;
    font-weight: bold;
    margin-bottom: $spacing * 2;
    padding: ($spacing * 3) ($spacing * 4);
  }

  .regretButton {
    align-self: center;
  }

  .submitButton {
    align-self: flex-start;
  }
}

.modalBody {
  margin-bottom: $spacing * 4;
  text-align: center;
}

.modalButtonGroup {
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: $screen-md) {
    flex-direction: row;
    justify-content: space-around;
    margin: auto ($spacing * 4);
  }

  .confirmButton {
    margin-bottom: $spacing * 3;

    @media (min-width: $screen-md) {
      margin-bottom: auto;
    }
  }
}
