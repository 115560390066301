@import 'variables';

.pageContainer {
  padding-left: $mobile-edge;
  padding-right: $mobile-edge;
}

.pageHeading {
  margin-bottom: $spacing * 4;
}

.borderContainer {
  @media (min-width: $screen-md) {
    border-radius: $border-radius;
    border: $border;
    border-color: $formds-common-black-180;
    padding: $spacing * 3;
  }
}

.fullDetails {
  margin-bottom: $spacing * 5;
}

.invoiceLinesHeading {
  margin-bottom: $spacing * 2;
}

.invoiceLinesContainer {
  margin-bottom: $spacing * 2;
}

.includingTaxMessage {
  margin-bottom: $spacing * 7;
}

.pdfButton,
.pdfButton > button {
  width: 100%;
  @media (min-width: $screen-md) {
    width: inherit;
  }
}
