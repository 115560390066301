@import 'variables';

.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: ($spacing * 6) auto ($spacing);

  @media (min-width: $screen-md) {
    align-items: center;
    flex-direction: row;
  }

  .label {
    margin-bottom: $spacing * 3;
    text-align: center;

    @media (min-width: $screen-md) {
      margin-bottom: 0;
      margin-right: $spacing * 3;
    }
  }

  .branding {
    align-self: center;
    max-width: 220px;

    .logo {
      width: 100%;
    }
  }
}
