@import '../../variables';

.navigationContainer {
  .sectionHeader {
    margin-bottom: ($spacing * 2);
    text-align: center;
  }
  .navigation {
    margin: 0;
    border: solid 1px $medium-grey;
    border-radius: 8px;
    padding: $spacing * 2;

    .navigationItem {
      border-bottom: 1px solid $medium-grey;
      display: flex;
      flex-direction: column;
      margin-bottom: 3px;

      &:last-child {
        border-bottom: 0;
        margin-bottom: 0;
      }

      .link {
        background-color: $white;
        border: 0;
        text-align: left;
        padding: $spacing * 2;
        text-decoration: none;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .linkDescription {
          margin-top: 2px;
        }
        .linkArrow {
          display: flex;
          align-items: center;
          margin-left: $spacing * 2;
        }

        &:hover {
          cursor: pointer;
          background-color: $light-grey;
        }
      }
    }
  }
}

@media (min-width: $screen-md) {
  .navigationContainer {
    .navigation {
      padding: $spacing * 3;
      .navigationItem {
        .link {
          padding: $spacing * 3;
        }
      }
    }
  }
}
