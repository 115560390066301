.billText {
  margin-bottom: 4px;
  display: block;
}

.billedForPeriod,
.startTime {
  font-weight: normal;
}

.startTime {
  margin-top: 4px;
  display: block;
}
