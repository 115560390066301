// Colors
$red: #e03127;
$medium-red: #a1362f;
$dark-red: #7b2f2b;
$blue: #1e569f;
$blue-info: #2e5978;
$white: #fff;
$light-grey: #f4f4f4;
$medium-grey: #d6d6d6;
$dark-grey: #6e6564;
$black: #302322;

$primary400: $red;
$primary500: $medium-red;
$primary600: $dark-red;

$secondary900: $black;
$secondary700: #544a4c;
$secondary200: #d9d6d7;
$secondary050: #faf9f9;

//Spacing
$spacing: 8px;
$mobile-edge: 16px;

// Breakpoints
// TODO(OLA): Breakpoints. Også i js. Mulighet for å dele variabler mellom js og css uten å kødde til CRA?
$screen-md: '768px';
$grid-narrow: 540px;
$grid-wide: 1140px;

// Fonts
$base-font-size: 16;

$headline1-sm-font-size: #{calc(36 / 16)}rem;
$headline1-sm-line-height: #{calc(46.8 / 16)}rem;
$headline1-md-font-size: #{calc(42 / 16)}rem;
$headline1-md-line-height: #{calc(54.6 / 16)}rem;

$headline5-sm-font-size: #{calc(20 / 16)}rem;
$headline5-sm-line-height: #{calc(26 / 16)}rem;
$headline5-md-font-size: #{calc(24 / 16)}rem;
$headline5-md-line-height: #{calc(31.2 / 16)}rem;

$headline6-xlg-font-size: #{calc(24 / 16)}rem;
$headline6-xlg-line-height: #{calc(31.2 / 16)}rem;
$headline6-lg-font-size: #{calc(20 / 16)}rem;
$headline6-lg-line-height: #{calc(26 / 16)}rem;
$headline6-md-font-size: #{calc(20 / 16)}rem;
$headline6-md-line-height: #{calc(26 / 16)}rem;
$headline6-sm-font-size: #{calc(18 / 16)}rem;
$headline6-sm-line-height: #{calc(23.4 / 16)}rem;
$headline6-md-font-size: #{calc(20 / 16)}rem;
$headline6-md-line-height: #{calc(26 / 16)}rem;

$uiText-sm-font-size: #{calc(16 / 16)}rem;
$uiText-sm-line-height: #{calc(20.8 / 16)}rem;
$uiText-md-font-size: #{calc(18 / 16)}rem;
$uiText-md-line-height: #{calc(23.4 / 16)}rem;

$paragraph-sm-font-size: #{calc(16 / 16)}rem;
$paragraph-sm-line-height: #{calc(28.8 / 16)}rem;
$paragraph-md-font-size: #{calc(18 / 16)}rem;
$paragraph-md-line-height: #{calc(25.6 / 16)}rem;
