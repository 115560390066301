@import '/src/app/component-library-wave/designtokens.scss';

@mixin base-input {
  .input {
    background: $neutrals-white;

    border: 1px solid $neutrals-300-tint;
    border-radius: 2px;

    box-shadow:
      inset 1px 1px 1px rgba(0, 0, 0, 0.05),
      inset 0 1px 2px rgba(0, 0, 0, 0.15),
      inset 1px 1px 4px rgba(0, 0, 0, 0.15),
      inset 0 2px 8px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    width: 100%;

    height: 43px;
    padding: 10px 16px;
    font-size: $text-s-regular-font-size;
    line-height: $text-s-regular-line-height;
    font-family: $text-s-regular-font-family;
    font-weight: $text-s-regular-font-weight;
    color: $secondary-900-shade;

    &.error {
      border: 2px solid $attention-500-tint-core;
    }

    &.password {
      padding-right: 7 * $spacing;
    }

    &:focus {
      box-shadow:
        -3px -3px 0 0 $guidance-500-shade-core,
        3px -3px 0 0 $guidance-500-shade-core,
        -3px 3px 0 0 $guidance-500-shade-core,
        3px 3px 0 0 $guidance-500-shade-core;
    }
  }

  .label {
    margin-bottom: $spacing;
    &.error {
      color: $attention-700-shade;
    }
  }

  .inputContainer {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
  }

  .eye {
    display: flex;
    cursor: pointer;
    margin-left: -44px;
    background: 0;
    border: 0;
    padding: 0;
  }
}

@mixin tablet-input {
  .input {
    height: 47px;
    padding: 12px 16px;
    font-size: $text-m-regular-font-size;
    line-height: $text-m-regular-line-height;
    font-family: $text-m-regular-font-family;
    font-weight: $text-m-regular-font-weight;
    color: $secondary-900-shade;
  }
  .inputContainer {
    margin-bottom: $spacing;
  }
}

@mixin desktop-input {
  .input {
    height: 55px;
    padding: 16px 16px;
    font-size: $text-l-regular-font-size;
    line-height: $text-l-regular-line-height;
    font-family: $text-l-regular-font-family;
    font-weight: $text-l-regular-font-weight;
    color: $secondary-900-shade;
  }
  .inputContainer {
    margin-bottom: $spacing;
  }
}
