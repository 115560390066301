@import 'src/variables';

.group {
  .heading {
    text-align: center;
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    column-gap: $spacing * 2;
    @media (min-width: $screen-md) {
      margin-bottom: 2 * $spacing;
    }
  }
}

.items {
  margin-bottom: 8 * $spacing;
  border: solid 1px $secondary200;
  border-radius: $border-radius;
  padding: (2 * $spacing);
  @media (min-width: $screen-md) {
    padding: (3 * $spacing);
  }
}

.stroke,
.fill {
  vertical-align: middle;
  margin-bottom: $spacing;
}

.stroke {
  stroke: $red;
}

.fill {
  fill: $red;
}

.imageContainer {
  width: $spacing * 3;
  height: $spacing * 4;
}
