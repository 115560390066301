@import '/src/app/component-library-wave/designtokens.scss';

.title {
  margin-bottom: $spacing;
  display: inline-block;
}

.selectContainer {
  display: grid;
  grid-template-areas: 'select';
  align-items: center;
  margin-bottom: $spacing;

  select {
    cursor: pointer;
    height: $spacing * 6;
    grid-area: select;
    appearance: none;
    padding-left: $spacing * 2;
  }

  select[multiple] {
    height: inherit;
    padding-right: 0;
    padding-left: 0;
  }
}

.multiple {
  option {
    padding-left: $spacing;
  }
}

.select {
  width: 100%;
  border: 1px solid $neutrals-300-tint;
  border-radius: 2px;
  box-shadow:
    inset 1px 1px 1px rgba(0, 0, 0, 0.05),
    inset 0px 1px 2px rgba(0, 0, 0, 0.15),
    inset 1px 1px 4px rgba(0, 0, 0, 0.15),
    inset 0px 2px 8px rgba(0, 0, 0, 0.1);

  padding: $spacing 0;
  font-size: $text-l-medium-font-size;
  line-height: $text-l-medium-line-height;
  background-color: $neutrals-white;
}

.selectContainer:not(.multiple)::after {
  grid-area: select;
  justify-self: end;
  content: '';
  width: 12px;
  height: 8px;
  margin-right: $spacing;
  background: url('data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPg0KPHBhdGggZD0iTTExIDFMNiA2TDAuOTk5OTk5IDEiIHN0cm9rZT0iYmxhY2siIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+DQo8L3N2Zz4NCg');
}

.disabled select {
  cursor: not-allowed;
}
