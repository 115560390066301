@import 'variables';

.timeline {
  display: flex;
  flex-direction: column;
  list-style: none;
  justify-content: space-between;
  margin-bottom: 0;
  margin-left: 0;
  margin-top: 0;
  padding: 0;
  position: relative;

  @media (min-width: $screen-md) {
    flex-direction: row;
  }

  &::before {
    content: '';
    background-color: $secondary-900;
    height: 100%;
    left: 67px;
    opacity: 0.4;
    position: absolute;
    width: 2px;

    @media (min-width: $screen-md) {
      height: 2px;
      left: 12px;
      top: 68px;
      width: calc(100% - 24px);
    }
  }
}
