@import 'variables';

.dwellingUnitNumberSiteName {
  text-align: left;
  float: left;
  margin-top: calc($spacing / 2);
  @media (min-width: $screen-md) {
    margin-bottom: $spacing;
  }
}
