@import 'variables';
@import '/src/app/component-library-wave/designtokens';

.container {
  .spinner {
    margin-bottom: 60px;
    @media (width >= 454px) {
      /* normal message wraps on two lines here */
      margin-bottom: 46px;
    }
  }

  .alert {
    margin-bottom: $spacing * 2;

    .infoColumns {
      display: flex;
      flex-direction: column;

      @media (min-width: $screen-m) {
        flex-direction: row;
      }

      .column {
        margin-right: $spacing * 4;
        margin-bottom: $spacing * 2;
      }
    }
  }
}
