@import 'variables';

.fixed {
  max-width: $grid-wide;
  margin: 0 auto ($spacing * 7);

  &.narrow {
    max-width: $grid-narrow;
  }

  &.medium {
    max-width: $grid-medium;
  }

  @media (min-width: $screen-md) {
    margin-bottom: 0;
  }
}
