@import 'variables';
@import 'src/app/component-library-wave/designtokens';

.toggleWrapper {
  border-bottom: $border;

  > div > label {
    word-break: break-word;
    margin-left: $mobile-edge;
    font-size: $headline5-sm-font-size;
    font-weight: $headline-xxs-font-weight;

    @media (min-width: $screen-md) {
      font-size: $headline-xxs-font-size;
    }
  }
}

.disabled {
  border-bottom: none;

  > div {
    margin-bottom: 0;
  }
}

.accessPointNameContainer {
  padding: $spacing $spacing * 2;
}

.accessPointName {
  margin-left: $mobile-edge;
  font-family: $paragraph-l-font-family;
  word-break: break-all;
}
