@import 'src/app/component-library-wave/designtokens';
@import 'variables';

.userView {
  margin-bottom: $spacing * 5;

  > div {
    padding-bottom: $spacing * 2;
  }

  .contactsBlock {
    border: none;
    margin: $spacing * 2 0 $spacing * 4 $spacing * 4;

    @media (min-width: $screen-s) {
      margin-left: $spacing * 2;
      display: flex;
      align-content: flex-start;
      column-gap: ($spacing * 3);
    }
  }

  .alertBox {
    border-bottom: none;
    margin-bottom: $spacing * 4;
    padding: $spacing * 4 0;
    @media (min-width: $screen-sm) {
      padding: $spacing * 2 0 $spacing * 2 0;
      margin-left: $spacing * 2;
      margin-right: $spacing * 2;
    }

    div {
      padding: 0;
    }

    > div:first-of-type {
      margin: $mobile-edge;
      margin-top: 0;
    }

    > div:not(:first-of-type) {
      margin: 0 $mobile-edge;
      @media (min-width: $screen-sm) {
        margin: 0 $spacing * 6 $spacing * 2 $spacing * 8;
      }
    }

    div > svg {
      display: none;
      @media (min-width: $screen-s) {
        display: block;
      }
    }

    button {
      align-self: flex-start;
    }
  }

  .alertInfo {
    font-weight: bold;
    padding-top: 2 * $spacing;
    @media (min-width: $screen-s) {
      padding-top: 4 * $spacing;
    }
  }

  .userIcon {
    margin: 4px $spacing ($spacing * 2);
    @media (min-width: $screen-s) {
      margin: 6px;
    }
  }

  .alert {
    padding: 0;
    margin-bottom: $spacing * 2;
  }

  .userName,
  .userRole {
    margin-bottom: $spacing;
  }

  .userEmail {
    overflow-wrap: anywhere;
  }

  .changeLoginDetails {
    display: flex;
    margin-left: $spacing * 4;
    flex-direction: column;
    @media (min-width: $screen-s) {
      flex-direction: row;
      margin-left: $spacing * 2;
    }
  }

  .changePasswordLink {
    color: $secondary-900;

    .changePasswordLinkText {
      color: $secondary-900;
      font-size: $uiText-sm-font-size;

      @media (min-width: $screen-s) {
        text-align: left;
      }
    }

    &:last-of-type {
      margin-top: $spacing * 2;
    }

    @media (min-width: $screen-s) {
      &:last-of-type {
        margin-top: 0;
        margin-left: $spacing * 2;
      }
    }
  }

  .buttonBar {
    display: flex;
    gap: $spacing * 4;
    flex-direction: column;
    padding: 0;
    margin-left: $spacing * 4;
    margin-bottom: $spacing * 4;
    border: none;

    button {
      margin: 0;
    }

    .alignLeft {
      align-self: flex-start;
    }

    @media (min-width: $screen-s) {
      margin-left: $spacing * 2;
      flex-direction: row;
    }
  }
}
