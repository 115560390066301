@import 'variables';

.streamingHeader {
  margin-bottom: ($spacing * 2);
  text-align: center;
}

.chromecast {
  margin-right: $spacing * 2;
  fill: $red;
}

.noActiveStreamingServices {
  margin-bottom: $spacing * 20;
  text-align: center;

  .remoteControl {
    margin-bottom: $spacing * 3;
    margin-top: $spacing * 6;
  }

  .paragraph {
    margin-bottom: $spacing;
  }

  .buttonLink {
    background-color: $primary-500;
    border-radius: 3px;
    color: $white;
    display: inline-block;
    font-weight: bold;
    margin-top: $spacing * 2;
    padding: 11px 16px;
    text-decoration: none;
  }
}
