@import 'src/variables';
@import 'src/app/component-library-wave/designtokens';

.confirmation {
  position: absolute;
  top: $heading-height;
  width: 100vw;
  background-color: $neutral-0;
  z-index: 100;
  padding: 0;

  @media (min-width: $grid-narrow) {
    padding: $spacing * 8 $spacing * 2;
  }

  .container {
    border-radius: $border-radius;
    padding: $spacing * 2 $spacing * 2;

    @media (min-width: $grid-narrow) {
      border: 1px solid $neutrals-200-tint;
      padding: $spacing * 4 $spacing * 4;
    }
  }
}
