@import 'src/variables';

.container {
  padding: 0 $mobile-edge;
  @media (min-width: $screen-md) {
    margin: 0 auto ($spacing * 7);
    max-width: $grid-wide;
  }

  .heading {
    text-align: center;
    margin: (7 * $spacing) 0 (5 * $spacing);
    @media (min-width: $screen-md) {
      margin: 0 0 (7 * $spacing);
    }
  }
}
