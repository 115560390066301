@import 'variables';

.pageContainer {
  padding-left: $mobile-edge;
  padding-right: $mobile-edge;

  .pageHeading {
    margin-bottom: $spacing;
  }

  .ingress {
    margin-bottom: $spacing * 5;
  }

  .invoiceLeadHeading {
    margin-bottom: $spacing * 2;
  }

  .detailsLink {
    display: flex;
    justify-content: center;
  }

  .borderContainer {
    margin-bottom: $spacing * 5;
    border: none;

    @media (min-width: $screen-md) {
      border-radius: $border-radius;
      padding: $spacing * 3;
      border: $border;
    }
  }

  .centerContainer {
    display: flex;
    justify-content: center;
  }

  .loadedInvoicesFromMessage {
    margin-bottom: $spacing * 2;
  }

  .previousInvoicesList {
    display: block;

    fds-icon[name='check_circle_filled'] {
      color: $common-semantic-green-80;
    }

    fds-icon[name='watch_later_filled'] {
      color: $common-semantic-orange-100;
    }

    fds-icon[name='error_filled'] {
      color: $common-semantic-red-80;
    }

    fds-nav-list-item:first-of-type li {
      border-top: 1px solid var(--border-color-default);
    }

    fds-nav-list-item:last-of-type li {
      border-bottom: 1px solid var(--border-color-default);
    }

    @media (min-width: $screen-md) {
      fds-nav-list-item:first-of-type li {
        border-top: none;
      }

      fds-nav-list-item:last-of-type li {
        border-bottom: none;
      }
    }
  }

  .loadMoreInvoices {
    margin-bottom: $spacing * 5;
    @media (min-width: $screen-md) {
      margin-bottom: $spacing * 7;
    }
  }

  .avtaleGiro {
    margin-bottom: $spacing * 6;
  }

  .pageBottomMargin {
    margin-bottom: $spacing * 7;
  }
}

.invoicingQuestionsHeader {
  margin-bottom: $spacing;
}

.invoicingQuestions {
  margin-bottom: $spacing * 4;
}

.detailsButtonContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  column-gap: $spacing * 2;
  row-gap: $spacing * 2;

  @media (min-width: $screen-md) {
    flex-direction: row;
  }
}
