@import '/src/app/component-library-wave/designtokens.scss';
@import '../typography.mixins';

@include font-base;

@include mobile-fonts;

// Tablet
@media (min-width: $screen-s) and (max-width: calc($screen-m - 1px)) {
  @include tablet-fonts;
}
// Desktop
@media (min-width: $screen-m) {
  @include desktop-fonts;
}
