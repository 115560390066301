@import 'variables';
@import 'src/app/component-library-wave/designtokens';

.container {
  display: grid;
  grid-template-columns: 1fr;
  margin-top: $spacing * 4;

  @media (min-width: $screen-md) {
    grid-template-columns: 1fr 1fr;
    column-gap: $spacing * 2;
  }

  @media (min-width: $screen-m) {
    grid-template-columns: 1fr 1fr 1fr;

    &.twoColumns {
      grid-template-columns: 1fr 1fr;
    }
  }

  .menu {
    margin-bottom: $spacing * 9;
  }
}

.greeting {
  word-wrap: break-word;
}

.welcome {
  margin-bottom: $spacing * 4;
  text-align: center;
  @media (min-width: $screen-md) {
    margin-top: ($spacing * 5);
  }

  @media (min-width: $screen-m) {
    margin-top: ($spacing * 7);
  }
}

.locationContainer {
  margin-bottom: 6 * $spacing;
}

.logo {
  height: 50px;
  display: inline;
}

.text {
  margin-bottom: $spacing * 2;
}
