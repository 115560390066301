@import 'variables';

.container {
  display: flex;
  flex-direction: column;
  gap: $spacing;
  padding: $spacing * 3;
  border-radius: $border-radius;
  border: $border;
  margin-bottom: $spacing * 3;
  @media (min-width: $screen-md) {
    margin-bottom: 0;
  }
}

.buttonRow {
  padding-top: $spacing * 2;
  display: flex;
  flex-direction: column;
  gap: $spacing * 2;
  @media (min-width: $screen-md) {
    gap: $spacing * 4;
    flex-direction: row;
  }
}
