@import 'src/variables';
@import 'src/app/component-library-wave/designtokens';

.headingText {
  margin-left: 0;
  margin-bottom: $spacing * 2;
}

.card {
  > div > div {
    margin-bottom: 0;
  }
}
