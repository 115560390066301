@import 'src/variables';
@import 'src/app/component-library-wave/designtokens';

.item {
  list-style: none;

  .productItem {
    font-weight: bold;
    padding: (2 * $spacing) 0 (2 * $spacing) (2 * $spacing);
    @media (min-width: $screen-md) {
      padding: (3 * $spacing) 0 (3 * $spacing) (3 * $spacing);
    }
  }
}

.item:not(:last-child) {
  border-bottom: 1px solid $secondary200;
}
