@import 'src/variables';
@import 'src/app/component-library-wave/designtokens';

.card {
  margin-bottom: $spacing;

  p:not(:last-child) {
    margin-bottom: $spacing * 4;
  }

  @media (min-width: $screen-sm) {
    margin-bottom: $spacing * 2;
  }

  .paragraphWithEmail {
    overflow-wrap: anywhere;
  }
}

.buttonBar {
  display: flex;
  column-gap: $spacing;
  justify-content: flex-end;
  width: 100%;
  padding-left: $spacing * 2;
  padding-right: $spacing * 2;

  button:last-child {
    margin-right: 0;
  }

  @media (min-width: $screen-sm) {
    padding: 0;
  }

  @media (min-width: $screen-md) {
    padding: 0 0 $spacing * 15;
  }
}

.heading {
  text-align: center;
  margin-bottom: ($spacing * 2);
  margin-right: ($spacing * 2);
  margin-left: ($spacing * 2);

  @media (min-width: $screen-sm) {
    margin-right: 0;
    margin-left: 0;
  }
}

.alert {
  margin: ($spacing * 2) ($spacing * 2) $spacing;

  @media (min-width: $screen-sm) {
    margin: ($spacing * 3) 0 ($spacing * 2);
  }
}
