@import 'variables';

.header {
  color: $white;
  background: $primary400;
  padding: 14px 18px;
  margin-bottom: ($spacing * 4);
  height: $heading-height;

  @media (min-width: $screen-md) {
    padding: 14px 24px;
    margin-bottom: 0;
  }

  .mainContentShortcut,
  .altiboxLink {
    color: $white;
    text-decoration: none;
    font-weight: 700;
    font-size: #{calc(18 / 16)}rem;
    letter-spacing: 0.5px;
    display: flex;
    align-items: center;
    padding: 4px;
    margin-right: auto;
  }

  .mainContentShortcut {
    position: absolute;
    left: -2000px;
    top: 1px;
    z-index: 999;
    color: #fff;
    background: #d73e35;
    padding: 0.25rem 0.875rem;

    &:focus {
      left: 2px;
      top: 2px;
    }
  }

  .headerInner {
    max-width: $grid-wide;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    place-items: center center;

    .altiboxLink {
      .linkText {
        padding-bottom: 1px;

        &:hover {
          border-bottom: solid 1px $white;
          padding-bottom: 0;
        }
      }

      .arrow {
        stroke: $white;
        @media (min-width: $screen-md) {
          margin: 0 12px 0 0;
        }
      }
    }

    .logo {
      height: 36px;
      grid-column-start: 2;
      @media (min-width: $screen-md) {
        height: 26px;
        margin: -7px 0 0;
      }
    }

    .logout {
      margin: 0 0 0 auto;
      padding: 13px 6px;

      @media (min-width: $screen-md) {
        border: solid 2px $white;
        border-radius: 4px;
        padding: 11px 16px;
      }
    }
  }
}
