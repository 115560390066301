@import 'variables';

.modalOverlay {
  position: fixed;
  height: 100vh;
  inset: 0;
  padding: ($spacing * 8) 0;
  width: 100vw;
  z-index: 9999;

  .modalCard {
    background-color: $neutral-0;
    border-radius: $border-radius;
    box-shadow: 0 0 ($spacing * 3) ($spacing * 2) rgb(0 0 0 / 15%);
    padding: ($spacing * 7) ($spacing * 4);

    @media (min-width: $screen-md) {
      max-width: $spacing * 67.5;
      margin: 0 auto;
    }

    .modalHeading {
      margin-bottom: $spacing * 3;
      text-align: center;
    }
  }
}
