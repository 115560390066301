@import 'variables';

.calloutBox {
  border-radius: $border-radius;
  padding: $spacing * 4 $spacing * 2 $spacing * 3;
  position: relative;
  text-align: left;

  @media (min-width: $screen-md) {
    margin-bottom: $spacing * 6;
  }

  &.step1Background {
    background-color: #d2b2bb;
  }

  &.step2Background {
    background-color: #fae5c1;
  }

  &.step3Background {
    background-color: #cbdae3;
  }

  &.step4Background {
    background-color: #f2b8b5;
  }

  &.step5Background {
    background-color: #b9d4ab;
  }

  .paragraph {
    margin-bottom: $spacing * 3;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .linkItem {
    font-weight: 700;
    margin-left: $spacing * 2;
  }
}
