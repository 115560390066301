@import 'src/variables';

.changePinContainer {
  margin: 0 $mobile-edge;

  @media (min-width: $screen-md) {
    margin: 0;
  }
}

.changePinHeadline {
  margin-bottom: 3 * $spacing;
}

.changePinParagraph {
  margin-bottom: 7 * $spacing;
}

.changePinFormContainer {
  background-color: $secondary-50;
  padding: 3 * $spacing;
  margin-bottom: 9 * $spacing;
}

.inputContainer {
  margin-bottom: 3 * $spacing;
}

.cancelButton {
  margin-right: 3 * $spacing;
}

.buttonContainer {
  display: flex;
}
