@import 'src/variables';
@import 'src/app/component-library-wave/designtokens';

.link {
  display: block;
  margin-top: $spacing * 3;
  margin-bottom: $spacing * 6;
  text-align: center;
  text-decoration: none;

  .text {
    border-bottom: 2px solid $black;
  }

  @media (min-width: $screen-sm) {
    margin-top: $spacing * 6;
  }

  .arrowLeft {
    stroke: $secondary-900-shade;
    margin-right: $spacing * 1.5;
    vertical-align: top;
  }

  .arrowRight {
    margin-left: $spacing * 1.5;
    stroke: $secondary-900-shade;
    margin-right: $spacing * 1.5;
    vertical-align: top;
  }
}
