@import 'src/variables';
@import 'src/app/component-library-wave/components/typography/formds-typography-tokens';

.container {
  margin-top: 0;
  margin-right: $spacing * 2;
  margin-bottom: $spacing * 5;
  margin-left: $spacing * 2;
  display: flex;
  flex-direction: column;
  row-gap: $spacing * 2;
  @media (min-width: $screen-md) {
    margin-top: 0;
    margin-right: $spacing * 4;
    margin-bottom: $spacing * 8;
    margin-left: $spacing * 4;
  }
}

.header {
  text-align: center;
}

.title,
.bottomGap {
  margin-bottom: $spacing * 4;
}

.tabbedContainer {
  max-width: 100%;
}

.section {
  display: flex;
  flex-direction: column;
  gap: $spacing * 2;
  margin-bottom: $spacing * 7;
  width: 100%;
}

.sectionContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: $spacing;
  margin-top: $spacing * 3;
}

.card {
  display: flex;
  flex-direction: column;
  gap: $spacing * 2;
}

.noRule {
  background-color: $neutrals-050-tint;
}

.chooseNumber {
  margin-bottom: $spacing * 5;
}

.newRuleButton {
  margin-top: $spacing * 4;
  margin-right: auto;
  margin-left: auto;
}

.buttonRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: $spacing * 4;
}

.loadingOverlay {
  user-select: none;
  pointer-events: none;
  filter: contrast(25%);
}

.cardParameter {
  padding-bottom: $spacing * 2;
  display: block;
}
