@import 'variables';

.consentContainer {
  margin: 0 $mobile-edge;

  .consentHeader {
    margin-bottom: $spacing * 3;
    @media (min-width: $screen-md) {
      margin-bottom: $spacing * 4;
    }
  }

  .consentParagraph {
    margin-bottom: $spacing * 3;
    @media (min-width: $screen-md) {
      margin-bottom: $spacing * 4;
    }
  }

  .card {
    border-radius: $border-radius;
    border: 1px solid $formds-common-black-180;
    padding: $spacing * 3;
    margin-bottom: $spacing * 2;
    display: flex;
    flex-direction: column;
    gap: $spacing;
  }

  .methodContainer {
    padding-top: $spacing * 2;
  }

  .withdraw {
    padding-bottom: $spacing * 4;
  }

  .infoIcon {
    color: $formds-common-semantic-orange-80;
  }

  .required {
    display: inline-flex;
    gap: $spacing;
  }
}
