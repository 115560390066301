@mixin create-column-classes($grid-columns, $gutter, $prefix) {
  @include loop-create-columns($grid-columns, $grid-columns, $gutter, $prefix);
}
@mixin loop-create-columns($index, $grid-columns, $gutter, $prefix) {
  @if $index > 0 {
    .col-#{$prefix}-#{$index} {
      display: block;
      flex: 0 0 percentage(calc($index / $grid-columns));
      max-width: percentage(calc($index / $grid-columns));
      padding: 0 calc($gutter / 2);
      box-sizing: border-box;
    }
    @include loop-create-columns($index - 1, $grid-columns, $gutter, $prefix);
  }
}
