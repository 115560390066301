@import 'src/variables';
@import 'src/app/component-library-wave/designtokens';

.desktopTwoColumns {
  margin: $spacing * 2;
  @media (min-width: $screen-md) {
    margin: $spacing * 4 $spacing * 2;
    display: flex;
    flex-direction: row-reverse;
    column-gap: $spacing * 3;
  }
}

.imageContainer {
  @media (min-width: $screen-md) {
    border: 0;
    padding: 0;
    margin-bottom: 0;
    max-width: 80%;
  }
}

.imageColumn {
  width: auto;
  align-items: center;
  @media (min-width: $screen-md) {
    width: 50%;
    display: flex;
    justify-content: center;
  }
}

.fwaModelType {
  font-size: $uiText-md-font-size;
  margin-top: $spacing * 4;
  margin-bottom: $spacing * 2;
  @media (min-width: $screen-md) {
    margin-top: $spacing * 5;
  }
}

.fwaInfo {
  font-size: $spacing * 2;
}

.buttonRow {
  margin-bottom: $spacing * 3;
  margin-top: $spacing * 2;
  @media (min-width: $screen-md) {
    margin-bottom: 0;
  }
}

.miniSpinner {
  margin-left: 2px;
  margin-right: 2px;
  width: 20px;
  height: 20px;
}

.moreInfoLink {
  border-bottom: solid;
  text-decoration: none;
}

.buttonContainer {
  width: 100%;
  padding: $spacing * 3 $spacing * 2;
  background: transparent;
  border: 0;
  display: flex;
  align-items: stretch;

  &:hover {
    cursor: pointer;
    background-color: $light-grey;
  }
}

.container {
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  flex: 2 1 auto;
}

.status {
  margin-top: $spacing;
  display: flex;
  gap: $spacing * 2;
  align-items: flex-start;
  text-align: left;
}

.statusInformationIcon {
  min-width: 25px;
  min-height: 25px;

  > svg {
    stroke: $black;
  }
}

.wireless {
  display: flex;
  gap: $spacing * 2;
  align-items: center;
}

.fmgContainer {
  display: flex;
  width: 100%;
  padding: $spacing * 3 $spacing * 2;
  gap: $spacing * 2;
  align-items: center;
}

.arrow {
  stroke: $black;
  align-self: center;
}
