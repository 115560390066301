@import 'variables';

.spinner {
  border: 0.3125rem solid #f3f3f3;
  border-radius: 50%;
  border-top: 0.3125rem solid $black;
  width: 3.125rem;
  height: 3.125rem;
  margin: auto;

  &.marginVertical {
    margin: 86px auto;
    @media (min-width: $screen-md) {
      margin: 144px auto;
    }
  }
  animation: spin 1s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

.noTextContainer {
  margin-top: $spacing * 3;
}
