@import 'variables';

.privacyAndConcentContainer {
  margin: 0 $mobile-edge;
}

.header,
.lead,
.navList {
  margin-bottom: $spacing * 4;
}

.navList {
  display: block;
}

.moreInfo {
  margin-bottom: $spacing * 6;
}
