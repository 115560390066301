@import 'variables';

.activationUnavailable {
  display: inline-block;
  margin-bottom: $spacing * 2;
}

.streamingService {
  margin: 0 $mobile-edge;

  .activationAlertContainer {
    margin-bottom: $spacing * 5;

    .activationAlertText {
      margin-bottom: $spacing * 3;
    }
  }

  .headingContainer {
    display: flex;
    margin-bottom: $spacing * 4;

    .streamingServiceH1 {
      margin-top: $spacing * 2;
    }
  }
}

.productInformation {
  margin-bottom: $spacing * 8;
  padding-left: 0;

  li {
    list-style: none;
    border-top: solid 1px $secondary-100;
    display: flex;
    justify-content: space-between;
    padding: $spacing * 3;

    &:last-of-type {
      border-bottom: solid 1px $secondary-100;
    }
  }
}

.alertContainer {
  margin-bottom: $spacing * 2;
}

.logoContainer {
  margin-right: $spacing * 4;

  .logo {
    max-width: 180px;
    @media (min-width: $screen-md) {
      min-width: 224px;
    }
  }
}

.errorAlert {
  margin-bottom: $spacing * 7;
}
