@import 'variables';

.questionContainer {
  &:hover {
    background-color: $secondary050;
  }

  button {
    width: 100%;
    text-align: left;
    background-color: transparent;
    border: 0;
    border-top: 1px solid $medium-grey;
    padding: ($spacing * 2) ($spacing * 3) ($spacing * 2) ($spacing * 2);
    cursor: pointer;
    display: flex;
    justify-content: space-between;

    @media (min-width: $screen-md) {
      padding: ($spacing * 3);
    }

    .arrow {
      height: 7px;
      width: 12px;
      align-self: center;
      margin-left: ($spacing * 2);
    }
  }

  &.last,
  &.isOpen {
    border-bottom: 1px solid $medium-grey;
  }
}

.answerContainer {
  margin: 0;

  .answer {
    padding-bottom: ($spacing * 2);
    padding-left: ($spacing * 2);
    padding-right: ($spacing * 2);

    @media (min-width: $screen-md) {
      padding-bottom: ($spacing * 3);
      padding-left: ($spacing * 3);
      padding-right: ($spacing * 3);

      &:first-child {
        padding-top: ($spacing * 3);
      }
    }

    &:first-child {
      padding-top: ($spacing * 2);
    }
  }

  .link {
    padding-left: ($spacing * 4);

    @media (min-width: $screen-md) {
      padding-left: ($spacing * 5);
    }
  }
}
