@import 'src/variables';

.header {
  margin-bottom: $spacing * 2;
  text-align: center;
}

.container {
  margin-bottom: $spacing * 9;

  @media (min-width: $screen-sm) {
    margin-bottom: $spacing * 15;
  }
}
