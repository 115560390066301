@import 'variables';

.settlementState {
  display: flex;
  gap: calc($spacing / 2);

  .open {
    color: $common-semantic-orange-100;
  }

  .settled {
    color: $common-semantic-green-80;
  }

  .overdue {
    color: $common-semantic-red-80;
  }
}
