@import 'src/app/component-library-wave/designtokens';

.domain {
  margin-top: 42px;
  margin-left: $spacing;

  @media (min-width: $screen-s) {
    margin-top: 47px;
  }
  @media (min-width: $screen-m) {
    margin-top: 50px;
  }
}

.helpText {
  margin-top: 2px;
  margin-left: $spacing;
  color: $neutrals-800-shade;

  &.disabled {
    color: $neutrals-300-tint;
  }
}

.helpTextContainer {
  display: flex;
  align-items: center;

  .iconContainer {
    display: flex;
    align-items: center;
  }
}

.infoIcon {
  max-width: 26px;
}
