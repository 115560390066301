@import 'variables';

.flexLocationPicker {
  display: flex;
}

.locationSelectorContainer {
  .title {
    margin-bottom: $spacing * 3;
    text-align: center;
  }

  .subTitle {
    margin-bottom: $spacing * 4;
    text-align: center;
  }
}

.toAddressContainer {
  margin-bottom: ($spacing * 6);
}

.nextStepContainer {
  text-align: right;
  margin-bottom: $spacing * 6;
}

// Large screens
@media (min-width: $screen-md) {
  .subTitle {
    margin-bottom: $spacing * 6;
  }

  .toAddressContainer,
  .nextStepContainer {
    margin-bottom: $spacing * 9;
  }
}
