@import 'variables';

.mobileEdge {
  margin: 0 $mobile-edge;
  @media (min-width: $screen-md) {
    margin: 0 auto;
  }
}

.heading {
  margin-bottom: $spacing * 2;
  text-align: center;
  word-break: break-word;
}

.formBorder {
  border: 1px solid $secondary-200;
  border-radius: $border-radius;
  padding: ($spacing * 4) ($spacing * 2);

  @media (min-width: $screen-md) {
    padding: $spacing * 4;
  }
  margin-bottom: $spacing * 3;

  .formRow {
    margin-bottom: $spacing * 3;
  }

  .formRowTall {
    margin-bottom: $spacing * 7;
  }

  .formRowEmail {
    display: flex;
  }
}

.buttonRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: $spacing * 7;

  .cancel {
    margin-right: $spacing * 3;
    @media (min-width: $screen-md) {
      justify-content: right;
    }
  }
}

.errorMessage {
  margin-bottom: $spacing * 7;
}
