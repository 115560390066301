@import '/src/app/component-library-wave/designtokens.scss';

.fieldset {
  border: none;
  margin: 0;
  padding: 0;

  .legend {
    color: $neutrals-800-shade;
    float: left;
    margin-bottom: $spacing * 3;
  }
  .optional {
    float: left;
    margin-left: $spacing * 2;
    color: $neutrals-700-shade;
  }
}
.radioRow {
  display: flex;
  align-items: center;
  clear: both;
  border-radius: 4px;
  margin-bottom: $spacing;
  margin-left: 5px;

  .radioInput {
    margin: 12px 0 12px 19px;
    cursor: pointer;
  }
  .radioLabel {
    color: $neutrals-800-shade;
    cursor: pointer;
    padding: 12px 16px 12px 15px;
    width: 100%;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
  &.disabled {
    .radioInput,
    .radioLabel {
      cursor: default;
      color: $neutrals-300-tint;
    }
  }
  &:hover {
    background-color: $guidance-050-tint;
  }
  &.success {
    .radioInput,
    .radioLabel {
      color: $positive-600-shade;
    }
  }
  &.error {
    .radioInput,
    .radioLabel {
      color: $attention-600-tint; /* TODO: $attention-700-tint, (#cc6200 contrast 3.91:1) */
    }
  }
  &.focused {
    background-color: $guidance-700-shade;
    .radioLabel {
      color: $neutrals-white;
    }
  }
}
.helpTextContainer {
  margin-top: $spacing * 3;
}
