@import 'src/variables';
@import 'src/app/component-library-wave/designtokens';

.container {
  margin: 0 $spacing * 2;
  margin-bottom: $spacing * 5;
  display: flex;
  flex-direction: column;
  row-gap: $spacing * 2;
  @media (min-width: $screen-md) {
    margin: 0 $spacing * 4;
    margin-bottom: $spacing * 8;
  }
}

.subModules {
  display: flex;
  flex-direction: column;
  row-gap: $spacing;
  margin-top: 0;
  @media (min-width: $screen-md) {
    row-gap: $spacing * 5;
    margin-top: $spacing * 5;
  }
}

.guestNetworkLink {
  margin-bottom: $spacing * 2;
}

.wifiContainer {
  background: $neutral-0;
  box-sizing: border-box;
  border-radius: $spacing;
  margin-bottom: $spacing * 3;
  text-align: left;
  list-style-type: none;
  border: 1px solid $secondary200;
}

.managedWifiContainer {
  background: $neutral-0;
  box-sizing: border-box;
  border-radius: $spacing;
  margin-bottom: $spacing;
  text-align: left;
  list-style-type: none;
}

.buttonRow {
  display: flex;
  background: none;
  padding: $spacing * 2 $spacing * 4;
  border: 1px solid $secondary200;
  border-radius: $spacing;
  align-items: center;
  gap: 10px;
  width: 100%;
  cursor: pointer;
  justify-content: space-between;

  > p {
    margin-left: auto;
  }
}

.bandHeader {
  margin-bottom: $spacing * 3;
  border-bottom: 1px solid $secondary-200;
}

.bandFrequencyHelp {
  padding-bottom: $spacing * 2;
}

.bandFrequencyHelpText {
  font-size: $uiText-md-font-size;
}

.bandToggle {
  > div {
    padding: 0;
    margin-bottom: $spacing * 2;
  }

  > div > label {
    margin-left: 0;
    font-size: $headline6-md-font-size;
    line-height: $headline-xxs-line-height;
    font-family: $text-xxs-medium-font-family;
    font-weight: $headline-xxs-font-weight;
    @media (min-width: $screen-md) {
      font-size: $headline-xxs-font-size;
    }
  }
}

.infoCircle {
  display: inline-block;
  vertical-align: middle;
}

.wifiList {
  padding-left: 0;
}

.toggle {
  padding-top: $spacing;

  > div {
    padding-left: 0;
    padding-right: 0;
  }
}

.informationText {
  display: flex;
  flex-direction: column;
  row-gap: $spacing;
}

.hgwWifiContainer {
  > div > div:not(:first-of-type) {
    padding-left: 0;
    margin: 0;

    > div {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

.ssidDetails {
  vertical-align: middle;
  padding-top: $spacing * 2;

  span {
    margin-right: $spacing * 2;
  }
  margin-bottom: $spacing * 2;
}
