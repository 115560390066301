$primary-900: #3f0e0b;
$primary-800: #701914;
$primary-700: #9d231b;
$primary-600: #c32b22;
$primary-500: #e03127;
$primary-400: #e65b52;
$primary-300: #ed847e;
$primary-200: #f4b7b4;
$primary-100: #fbe6e5;
$primary-50: #fdf5f4;
$secondary-900: #302322;
$secondary-800: #3e3435;
$secondary-700: #544a4c;
$secondary-600: #756d6f;
$secondary-500: #979092;
$secondary-400: #a7a1a3;
$secondary-300: #bdb9ba;
$secondary-200: #d9d6d7;
$secondary-100: #f2f1f1;
$secondary-50: #faf9f9;
$support-1-900: #453924;
$support-1-800: #7b6540;
$support-1-700: #ad8e5a;
$support-1-600: #d7b16f;
$support-1-500: #f7cb80;
$support-1-400: #f8d393;
$support-1-300: #f9d8a0;
$support-1-200: #fbe5bf;
$support-1-100: #fdf2df;
$support-1-50: #fef9f0;
$support-2-900: #2f363b;
$support-2-800: #536069;
$support-2-700: #758793;
$support-2-600: #91a8b7;
$support-2-500: #a7c1d2;
$support-2-400: #b9cddb;
$support-2-300: #cadae4;
$support-2-200: #e0e9ef;
$support-2-100: #f4f8fa;
$support-2-50: #fbfcfd;
$support-3-900: #240911;
$support-3-800: #400f1e;
$support-3-700: #5a162b;
$support-3-600: #6f1b35;
$support-3-500: #801f3d;
$support-3-400: #994c64;
$support-3-300: #b3798b;
$support-3-200: #d3b1bb;
$support-3-100: #f0e4e8;
$support-3-50: #f9f4f5;
$guidance-500-core: #017ea1;

// Neutral
$neutral-0: #fff;
$neutral-300: #989696;
$neutrals-800-shade: #292828;

// Colors (deprecated)
$red: #e03127;
$medium-red: #a1362f;
$dark-red: #7b2f2b;
$blue: #1e569f;
$blue-info: #2e5978;
$white: #fff;
$light-grey: #f4f4f4;
$medium-grey: #d6d6d6;
$dark-grey: #6e6564;
$black: #302322;
$light-blue: #b0d9e4;

// Invoice Settlement Status icon
$common-semantic-red-80: #ca3629;
$common-semantic-orange-100: #db7b10;
$common-semantic-green-80: #1a8642;

// Link
$link: #016e8c;
$link-visited: $support-3-600;
$primary400: $red;
$primary500: $medium-red;
$primary600: $dark-red;
$secondary900: $black;
$secondary700: #544a4c;
$secondary200: #d9d6d7;
$secondary050: #faf9f9;

// Breakpoints
// AKW-4264
$screen-cookiebot: 600px;
$screen-sm: 480px;
$screen-md: 768px;
$screen-lg: 960px;
$grid-narrow: 540px;
$grid-medium: 652px;
$grid-wide: 1140px;
$heading-height: 80px;
$navigation-breakpoint-small: 667px;
$navigation-breakpoint-medium: 960px;
$navigation-breakpoint-large: 1280px;

// Fonts
$base-font-size: 16;
$headline1-sm-font-size: #{calc(36 / 16)}rem;
$headline1-sm-line-height: #{calc(46.8 / 16)}rem;
$headline1-md-font-size: #{calc(42 / 16)}rem;
$headline1-md-line-height: #{calc(54.6 / 16)}rem;
$headline2-sm-font-size: #{calc(32 / 16)}rem;
$headline2-sm-line-height: #{calc(41.6 / 16)}rem;
$headline2-md-font-size: #{calc(36 / 16)}rem;
$headline2-md-line-height: #{calc(46.8 / 16)}rem;
$headline4-sm-font-size: #{calc(24 / 16)}rem;
$headline4-sm-line-height: #{calc(31.2 / 16)}rem;
$headline4-md-font-size: #{calc(28 / 16)}rem;
$headline4-md-line-height: #{calc(36.4 / 16)}rem;
$headline5-sm-font-size: #{calc(20 / 16)}rem;
$headline5-sm-line-height: #{calc(26 / 16)}rem;
$headline5-md-font-size: #{calc(24 / 16)}rem;
$headline5-md-line-height: #{calc(31.2 / 16)}rem;
$headline6-xlg-font-size: #{calc(24 / 16)}rem;
$headline6-xlg-line-height: #{calc(31.2 / 16)}rem;
$headline6-lg-font-size: #{calc(20 / 16)}rem;
$headline6-lg-line-height: #{calc(26 / 16)}rem;
$headline6-md-font-size: #{calc(20 / 16)}rem;
$headline6-md-line-height: #{calc(26 / 16)}rem;
$headline6-sm-font-size: #{calc(18 / 16)}rem;
$headline6-sm-line-height: #{calc(23.4 / 16)}rem;
$headline6-md-line-height: #{calc(26 / 16)}rem;
$uiText-sm-font-size: #{calc(16 / 16)}rem;
$uiText-sm-line-height: #{calc(20.8 / 16)}rem;
$uiText-md-font-size: #{calc(18 / 16)}rem;
$uiText-md-line-height: #{calc(23.4 / 16)}rem;
$paragraph-sm-font-size: #{calc(16 / 16)}rem;
$paragraph-sm-line-height: #{calc(28.8 / 16)}rem;
$paragraph-md-font-size: #{calc(18 / 16)}rem;
$paragraph-md-line-height: #{calc(25.6 / 16)}rem;

// FormDS
$formds-common-blue-100: #2e8eff;
$formds-common-black-180: #e3e3e3;
$formds-common-black-180: #e3e3e3;
$formds-common-semantic-orange-80: #af620d;
$formds-altibox-confirm: #43a047;
$formds-altibox-secondary-190: #eae9e9;
$formds-neutrals-tint-200: #c3c2c2;

/* UI/Text S */
$formds-ui-s-font-family: Roboto;
$formds-ui-s-font-size: #{calc(16 / 16)}rem;
$formds-ui-s-font-style: normal;
$formds-ui-s-font-weight: 400;
$formds-ui-s-line-height: #{calc(20.8 / 16)};
$formds-ui-s-font-weight-bold: 700;

/* UI/Text M */
$formds-ui-m-font-family: Roboto;
$formds-ui-m-font-size: #{calc(18 / 16)}rem;
$formds-ui-m-font-style: normal;
$formds-ui-m-font-weight: 400;
$formds-ui-m-line-height: #{calc(23.4 / 16)};
$formds-ui-m-font-weight-bold: 700;

/* UI/Text L */
$formds-ui-l-font-family: Roboto;
$formds-ui-l-font-size: #{calc(20 / 16)}rem;
$formds-ui-l-font-style: normal;
$formds-ui-l-font-weight: 400;
$formds-ui-l-line-height: #{calc(26 / 16)};
$formds-ui-l-font-weight-bold: 700;

/* Paragraph/Paragraph XL */
$formds-paragraph-xl-font-size: #{calc(24 / 16)}rem;
$formds-paragraph-xl-line-height: #{calc(38.4 / 16)}rem;
$formds-paragraph-xl-font-weight: 400;
$formds-paragraph-xl-font-family: roboto 'sans-serif';

/* Paragraph/Paragraph M */
$formds-paragraph-m-font-family: roboto;
$formds-paragraph-m-font-size: #{calc(18 / 16)}rem;
$formds-paragraph-m-font-weight: 400;
$formds-paragraph-m-line-height: #{calc(28.8 / 16)}rem;
$formds-paragraph-m-letter-spacing: 0.09px;

/* Headlines/Headline XS */
$formds-headline-xs-font-family: gt walsheim pro;
$formds-headline-xs-font-size: #{calc(28 / 16)}rem;
$formds-headline-xs-font-weight: 700;
$formds-headline-xs-line-height: #{calc(36.4 / 16)}rem;

/* Headlines/Headline XXS */
$formds-headline-xxs-font-family: gt walsheim pro;
$formds-headline-xxs-font-size: #{calc(24 / 16)}rem;
$formds-headline-xxs-font-weight: 700;
$formds-headline-xxs-line-height: #{calc(31.2 / 16)}rem;

/* Headlines/Headline XXXS */
$formds-headline-xxxs-font-family: gt walsheim pro;
$formds-headline-xxxs-font-size: #{calc(20 / 16)}rem;
$formds-headline-xxxs-font-weight: 700;
$formds-headline-xxxs-line-height: #{calc(26 / 16)}rem;

/* Headlines/Headline M */
$formds-headline-m-font-family: gt walsheim pro;
$formds-headline-m-font-size: #{calc(36 / 16)}rem;
$formds-headline-m-font-weight: 700;
$formds-headline-m-line-height: #{calc(46.8 / 16)}rem;

// Input shadows
$box-shadow:
  inset 1px 1px 1px rgb(0 0 0 / 5%),
  inset 0 1px 2px rgb(0 0 0 / 15%),
  inset 1px 1px 4px rgb(0 0 0 / 15%),
  inset 0 2px 8px rgb(0 0 0 / 10%);

// Spacing
$spacing: 8px;
$mobile-edge: 16px;
$border-radius: 8px;
$border: 1px solid $formds-neutrals-tint-200;
