@import 'src/variables';
@import 'src/app/component-library-wave/designtokens';

.subHeader {
  line-height: $paragraph-xl-line-height;
}

.section {
  margin-bottom: $spacing * 4;
}

.noBreak {
  white-space: nowrap;
}

.buttonRow {
  display: flex;
  flex-direction: column;
  @media (min-width: $screen-md) {
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 0;
  }
}

.dismissButton {
  border: 2px solid $secondary-900;
  border-radius: 4px;
  background: none;
  padding: 11px 0;
  margin: 6px;
  cursor: pointer;

  &:focus-visible {
    outline-offset: 3px;
    outline: solid 3px $guidance-500-core;
  }

  @media (min-width: $screen-md) {
    border: inherit;
    margin-left: 0;
  }
}

.dismissButtonText {
  font-weight: 700;
  padding-bottom: 2px;
  border-bottom: 2px solid $secondary-900;
}

.information {
  margin-top: $spacing * 4;
}
