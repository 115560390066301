@import 'variables';

.relocationSummary {
  .heading,
  .ingress,
  .notification {
    text-align: center;
  }

  .heading {
    margin-bottom: $spacing * 2;
  }

  .ingress {
    margin-bottom: $spacing * 3;
  }

  .notification {
    margin-bottom: $spacing * 4;
  }

  .summaryCard {
    border: 1px solid $secondary200;
    border-radius: $border-radius;
    margin-bottom: $spacing * 6;
    padding: ($spacing * 4) ($spacing * 3) ($spacing * 3);

    .cardHeading {
      margin-bottom: $spacing * 4;
      text-align: center;
    }

    .section {
      margin-bottom: $spacing * 4;

      &:last-child {
        margin-bottom: 0;
      }

      .sectionHeading {
        margin-bottom: $spacing * 2;
      }

      .sectionList {
        list-style-type: none;
        margin: 0;
        padding: 0;

        .listItem {
          display: flex;
          margin-bottom: $spacing * 2;

          &:last-child {
            margin-bottom: 0;
          }

          .itemPrefix {
            margin-right: $spacing * 3;
          }
        }
      }

      .sectionInfo {
        margin-top: $spacing * 2;
      }
    }

    .movingDates > dd {
      display: flex;
      margin: 0 0 $spacing * 2;

      .itemIcon {
        margin-right: $spacing * 3;
        width: $spacing * 2;
      }
    }
  }

  .stepNavigationContainer {
    display: flex;
    justify-content: space-between;

    @media (min-width: $screen-md) {
      margin-bottom: $spacing * 7;
    }
  }
}
