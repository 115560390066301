@import 'src/variables';
@import '/src/app/component-library-wave/designtokens.scss';

.toggle-container-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ($spacing * 1) ($spacing * 2);
  border-radius: 4px;
  margin-bottom: 6px;

  &:hover {
    background-color: $guidance-050-tint;
  }
  &.focused {
    .label {
      color: $neutrals-white;
    }
    background-color: $guidance-700-shade;
  }
  &.disabled {
    .toggleInput {
      cursor: default;
    }
    &:hover {
      background-color: transparent;
    }
    .label {
      color: $neutrals-300-tint;
    }
    .track {
      background: $positive-200-tint;
      &.off {
        background: $neutrals-300-tint;
      }
    }
    .thumb {
      background: $neutrals-100-tint;
      &.off {
        background: $neutrals-050-tint;
      }
    }
  }
}

.toggle-container {
  display: flex;
  align-items: center;
}

.toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 3.375em;
  height: 2em;
  margin: 0 10px;

  .toggleInput {
    cursor: pointer;
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    z-index: 2;
  }
}

.track {
  position: absolute;
  background: $positive-500-shade-core;
  border-radius: 24px;
  transition: all ease-in-out 0.2s;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;

  &.off {
    background: $neutrals-200-tint;
  }
}

.thumb {
  z-index: 1;
  border-radius: 50%;
  height: 1.5em;
  width: 1.5em;
  background: $neutrals-white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  transform: translateX(11px);
  transition: all ease-in-out 0.2s;

  &.off {
    transform: translateX(-11px);
  }
}
