@import 'variables';

.dateSelectorContainer {
  .heading {
    margin-bottom: $spacing * 4;
    text-align: center;
  }

  .dateOptions {
    margin: 0 auto ($spacing * 4);

    @media (min-width: $screen-md) {
      width: $spacing * 43;
    }
  }

  .dateSelection {
    margin-bottom: $spacing * 2;
  }

  .subscriptionPeriodHeading {
    text-align: left;
    margin-bottom: $spacing;
  }

  .subscriptionPeriodDescription {
    margin-bottom: $spacing * 6;
  }

  .stepNavigationContainer {
    display: flex;
    justify-content: space-between;

    @media (min-width: $screen-md) {
      margin-bottom: $spacing * 7;
    }
  }
}
