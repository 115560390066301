@import 'src/variables';
@import 'src/app/component-library-wave/designtokens';

$toast-width: 344px;
$toast-height: 80px;
$common-green-green-80: #1a8642;
$common-green-green-190: #e9f6ee;
$common-semantic-orange-80: #af620d;
$common-semantic-orange-190: #fbf2e7;
$common-semantic-red-80: #ca3629;
$common-semantic-red-190: #ffeceb;
$icon-width: 24px;
$icon-height: 24px;

.toastContainer {
  position: sticky;
  display: flex;
  align-items: flex-end;
  height: 0;
  width: 100%;
  top: $heading-height;
  z-index: 10;
  flex-direction: column;
  padding-right: $spacing;
  padding-left: $spacing;
}

.toast {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: $spacing * 2;
  margin-bottom: $spacing;
  width: 100%;
  border-radius: $border-radius;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  @media (min-width: $screen-md) {
    max-width: $toast-width;
  }
}

.success {
  background-color: $common-green-green-190;
  border: 1px solid $common-green-green-80;
}

.critical,
.failure {
  background-color: $common-semantic-red-190;
  border: 1px solid $common-semantic-red-80;
}

.info,
.warning {
  background-color: $common-semantic-orange-190;
  border: 1px solid $common-semantic-orange-80;
}

.iconInfo {
  display: flex;
  margin-right: $spacing;

  svg {
    margin-bottom: -5px;
  }
}

.iconFailure,
.iconWarning,
.iconSuccess {
  display: flex;
  margin-right: $spacing * 2;

  svg {
    height: $icon-height;
    width: $icon-width;
  }
}
