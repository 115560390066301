@import 'variables';

.pageContainer {
  margin: 0 $mobile-edge;
}

.heading,
.lead {
  margin-bottom: $spacing * 3;
  @media (min-width: $screen-md) {
    margin-bottom: $spacing * 4;
  }
}

.card {
  border-radius: $border-radius;
  border: 1px solid $formds-common-black-180;
  margin-bottom: $spacing * 5;
  padding: $spacing * 3;
}

.cardDescription {
  margin-bottom: $spacing * 2;
}
