@import '/src/app/component-library-wave/designtokens.scss';

.alertContainer {
  border-radius: 4px;
}

.expandDismissContainer {
  display: flex;

  .dismiss {
    background: none;
    border: 0;
    padding: 0 ($spacing * 2) 0 $spacing;
    &:hover {
      cursor: pointer;
    }
  }
}

.info {
  background-color: $guidance-100-tint;
}
.success {
  background-color: $positive-100-tint;
}
.warning {
  background-color: $attention-100-tint;
}
.critical {
  background-color: $critical-100-tint;
}

.heading {
  .col1 {
    display: flex;
    align-items: center;

    .icon {
      margin-right: $spacing * 2;
      min-width: 32px;
    }
    .headingText {
      margin-right: $spacing;
    }
  }

  .expanded {
    transform: rotate(180deg);
  }
  &.halfPaddingRight {
    padding-right: $spacing;
  }
  &.withoutIcon {
    padding-left: $spacing * 8;
  }
}

.body {
  padding: 0 ($spacing * 6) ($spacing * 4) ($spacing * 8);
  &.uiComponentSizeSmall {
    padding: 0 ($spacing * 2) ($spacing * 4);
  }
}
