@import 'src/variables';
@import 'src/app/component-library-wave/designtokens';

.usersPage {
  margin-left: $mobile-edge;
  margin-right: $mobile-edge;

  @media (min-width: ($grid-narrow + ($mobile-edge * 2))) {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: ($spacing * 4);
  }

  .heading {
    margin-bottom: $spacing;
  }

  .banner {
    margin: 0 $mobile-edge;

    @media (min-width: $screen-sm) {
      margin: 0;
    }
  }

  .otherUsersList {
    padding: 0;

    .otherUser {
      list-style: none;
    }
  }

  .sectionHeader {
    margin: ($spacing * 3) 0 ($spacing * 2);
    text-align: center;

    @media (min-width: $screen-sm) {
      margin-top: $spacing * 7;
    }
  }

  .buttonBar {
    display: flex;
    justify-content: center;
    margin-top: $spacing * 4;
  }

  .toast {
    margin-bottom: $spacing * 8;
    overflow-wrap: anywhere;
  }
}
