@import 'variables';

.menuPageHeader {
  margin-bottom: ($spacing * 2);
  text-align: center;
}

.container {
  margin: auto $mobile-edge ($spacing * 9);
  @media (min-width: $navigation-breakpoint-small) {
    margin: auto ($spacing * 4) ($spacing * 9);
  }
  @media (min-width: $navigation-breakpoint-medium) {
    width: 455px;
    margin: 0 auto ($spacing * 9);
  }
}
