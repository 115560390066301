@import 'variables';

.relocation {
  margin: auto ($spacing * 2);
}

// Large screens
@media (min-width: $screen-md) {
  .relocation {
    margin: auto;
  }
}
