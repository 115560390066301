@import 'variables';

.locationSelectorContainer {
  margin: auto $mobile-edge;
  text-align: center;

  .title {
    margin-bottom: $spacing * 3;
  }

  .subTitle {
    margin-bottom: $spacing * 4;
  }
}

// Large screens
@media (min-width: $screen-md) {
  .locationSelectorContainer {
    margin: inherit;
  }

  .title {
    margin-top: $spacing * 9;
  }

  .subTitle {
    margin-bottom: $spacing * 6;
  }
}
