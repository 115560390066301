@import 'src/variables';
@import 'src/app/component-library-wave/designtokens';

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: $spacing * 4;
  padding-left: $mobile-edge;
  padding-right: $mobile-edge;
  @media (min-width: $screen-md) {
    display: flex;
    background: $white;
    margin: auto;
    align-items: left;
    padding-left: $mobile-edge * 2;
    padding-right: $mobile-edge * 2;
  }
}

.headline {
  word-break: break-word;
}
