$text-xxs-medium-font-size: 0.75rem;
$text-xxs-medium-line-height: 0.9749999642372131rem;
$text-xxs-medium-font-family: 'Roboto', 'sans-serif';
$text-xxs-medium-font-weight: 500;
$text-xxs-medium-color: #000000;

$text-xs-medium-font-size: 0.875rem;
$text-xs-medium-line-height: 1.1374999284744263rem;
$text-xs-medium-font-family: 'Roboto', 'sans-serif';
$text-xs-medium-font-weight: 500;
$text-xs-medium-color: #000000;

$text-s-medium-font-size: 1rem;
$text-s-medium-line-height: 1.2999999523162842rem;
$text-s-medium-font-family: 'Roboto', 'sans-serif';
$text-s-medium-font-weight: 500;
$text-s-medium-color: #000000;

$text-m-medium-font-size: 1.125rem;
$text-m-medium-line-height: 1.462499976158142rem;
$text-m-medium-font-family: 'Roboto', 'sans-serif';
$text-m-medium-font-weight: 500;
$text-m-medium-color: #000000;

$text-xl-medium-font-size: 1.5rem;
$text-xl-medium-line-height: 1.9499999284744263rem;
$text-xl-medium-font-family: 'Roboto', 'sans-serif';
$text-xl-medium-font-weight: 500;
$text-xl-medium-color: #000000;

$text-l-medium-font-size: 1.25rem;
$text-l-medium-line-height: 1.625rem;
$text-l-medium-font-family: 'Roboto', 'sans-serif';
$text-l-medium-font-weight: 500;
$text-l-medium-color: #000000;

$text-xxs-italic-font-size: 0.75rem;
$text-xxs-italic-line-height: 0.9749999642372131rem;
$text-xxs-italic-font-family: 'Roboto', 'sans-serif';
$text-xxs-italic-font-weight: 400;
$text-xxs-italic-font-style: 'italic';
$text-xxs-italic-color: #000000;

$text-s-underlined-font-size: 1rem;
$text-s-underlined-line-height: 1.2999999523162842rem;
$text-s-underlined-font-family: 'Roboto', 'sans-serif';
$text-s-underlined-font-weight: 400;
$text-s-underlined-text-decoration: 'underline';
$text-s-underlined-color: #000000;

$text-xs-underlined-font-size: 0.875rem;
$text-xs-underlined-line-height: 1.1374999284744263rem;
$text-xs-underlined-font-family: 'Roboto', 'sans-serif';
$text-xs-underlined-font-weight: 400;
$text-xs-underlined-text-decoration: 'underline';
$text-xs-underlined-color: #000000;

$text-l-underlined-font-size: 1.25rem;
$text-l-underlined-line-height: 1.625rem;
$text-l-underlined-font-family: 'Roboto', 'sans-serif';
$text-l-underlined-font-weight: 400;
$text-l-underlined-text-decoration: 'underline';
$text-l-underlined-color: #000000;

$text-xl-underlined-font-size: 1.5rem;
$text-xl-underlined-line-height: 1.9499999284744263rem;
$text-xl-underlined-font-family: 'Roboto', 'sans-serif';
$text-xl-underlined-font-weight: 400;
$text-xl-underlined-text-decoration: 'underline';
$text-xl-underlined-color: #000000;

$text-m-underlined-font-size: 1.125rem;
$text-m-underlined-line-height: 1.462499976158142rem;
$text-m-underlined-font-family: 'Roboto', 'sans-serif';
$text-m-underlined-font-weight: 400;
$text-m-underlined-text-decoration: 'underline';
$text-m-underlined-color: #000000;

$text-xxs-regular-font-size: 0.75rem;
$text-xxs-regular-line-height: 0.9749999642372131rem;
$text-xxs-regular-font-family: 'Roboto', 'sans-serif';
$text-xxs-regular-font-weight: 400;
$text-xxs-regular-color: #000000;

$text-xxs-bold-font-size: 0.75rem;
$text-xxs-bold-line-height: 0.9749999642372131rem;
$text-xxs-bold-font-family: 'Roboto', 'sans-serif';
$text-xxs-bold-font-weight: 700;
$text-xxs-bold-color: #c4c4c4;

$text-xxs-underlined-font-size: 0.75rem;
$text-xxs-underlined-line-height: 0.9749999642372131rem;
$text-xxs-underlined-font-family: 'Roboto', 'sans-serif';
$text-xxs-underlined-font-weight: 400;
$text-xxs-underlined-text-decoration: 'underline';
$text-xxs-underlined-color: #000000;

$text-s-bold-font-size: 1rem;
$text-s-bold-line-height: 1.2999999523162842rem;
$text-s-bold-font-family: 'Roboto', 'sans-serif';
$text-s-bold-font-weight: 700;
$text-s-bold-color: #c4c4c4;

$text-l-bold-font-size: 1.25rem;
$text-l-bold-line-height: 1.625rem;
$text-l-bold-font-family: 'Roboto', 'sans-serif';
$text-l-bold-font-weight: 700;
$text-l-bold-color: #c4c4c4;

$text-l-italic-font-size: 1.25rem;
$text-l-italic-line-height: 1.625rem;
$text-l-italic-font-family: 'Roboto', 'sans-serif';
$text-l-italic-font-weight: 400;
$text-l-italic-font-style: 'italic';
$text-l-italic-color: #000000;

$text-xl-italic-font-size: 1.5rem;
$text-xl-italic-line-height: 1.9499999284744263rem;
$text-xl-italic-font-family: 'Roboto', 'sans-serif';
$text-xl-italic-font-weight: 400;
$text-xl-italic-font-style: 'italic';
$text-xl-italic-color: #000000;

$text-xl-regular-font-size: 1.5rem;
$text-xl-regular-line-height: 1.9499999284744263rem;
$text-xl-regular-font-family: 'Roboto', 'sans-serif';
$text-xl-regular-font-weight: 400;
$text-xl-regular-color: #000000;

$text-s-italic-font-size: 1rem;
$text-s-italic-line-height: 1.2999999523162842rem;
$text-s-italic-font-family: 'Roboto', 'sans-serif';
$text-s-italic-font-weight: 400;
$text-s-italic-font-style: 'italic';
$text-s-italic-color: #000000;

$text-m-regular-font-size: 1.125rem;
$text-m-regular-line-height: 1.462499976158142rem;
$text-m-regular-font-family: 'Roboto', 'sans-serif';
$text-m-regular-font-weight: 400;
$text-m-regular-color: #000000;

$text-s-regular-font-size: 1rem;
$text-s-regular-line-height: 1.2999999523162842rem;
$text-s-regular-font-family: 'Roboto', 'sans-serif';
$text-s-regular-font-weight: 400;
$text-s-regular-color: #c4c4c4;

$text-xs-regular-font-size: 0.875rem;
$text-xs-regular-line-height: 1.1374999284744263rem;
$text-xs-regular-font-family: 'Roboto', 'sans-serif';
$text-xs-regular-font-weight: 400;
$text-xs-regular-color: #000000;

$text-l-regular-font-size: 1.25rem;
$text-l-regular-line-height: 1.625rem;
$text-l-regular-font-family: 'Roboto', 'sans-serif';
$text-l-regular-font-weight: 400;
$text-l-regular-color: #000000;

$text-m-bold-font-size: 1.125rem;
$text-m-bold-line-height: 1.462499976158142rem;
$text-m-bold-font-family: 'Roboto', 'sans-serif';
$text-m-bold-font-weight: 700;
$text-m-bold-color: #000000;

$text-xs-italic-font-size: 0.875rem;
$text-xs-italic-line-height: 1.1374999284744263rem;
$text-xs-italic-font-family: 'Roboto', 'sans-serif';
$text-xs-italic-font-weight: 400;
$text-xs-italic-font-style: 'italic';
$text-xs-italic-color: #000000;

$text-xl-bold-font-size: 1.5rem;
$text-xl-bold-line-height: 1.9499999284744263rem;
$text-xl-bold-font-family: 'Roboto', 'sans-serif';
$text-xl-bold-font-weight: 700;
$text-xl-bold-color: #c4c4c4;

$text-m-italic-font-size: 1.125rem;
$text-m-italic-line-height: 1.462499976158142rem;
$text-m-italic-font-family: 'Roboto', 'sans-serif';
$text-m-italic-font-weight: 400;
$text-m-italic-font-style: 'italic';
$text-m-italic-color: #000000;

$text-xs-bold-font-size: 0.875rem;
$text-xs-bold-line-height: 1.1374999284744263rem;
$text-xs-bold-font-family: 'Roboto', 'sans-serif';
$text-xs-bold-font-weight: 700;
$text-xs-bold-color: #c4c4c4;

$paragraph-xl-font-size: 1.5rem;
$paragraph-xl-line-height: 2.4000000953674316rem;
$paragraph-xl-font-family: 'Roboto', 'sans-serif';
$paragraph-xl-font-weight: 400;
$paragraph-xl-color: #000000;

$paragraph-xs-font-size: 0.875rem;
$paragraph-xs-line-height: 1.399999976158142rem;
$paragraph-xs-font-family: 'Roboto', 'sans-serif';
$paragraph-xs-font-weight: 400;
$paragraph-xs-color: #000000;

$paragraph-m-font-size: 1.125rem;
$paragraph-m-line-height: 1.8000000715255737rem;
$paragraph-m-font-family: 'Roboto', 'sans-serif';
$paragraph-m-font-weight: 400;
$paragraph-m-color: #000000;

$paragraph-s-font-size: 1rem;
$paragraph-s-line-height: 1.600000023841858rem;
$paragraph-s-font-family: 'Roboto', 'sans-serif';
$paragraph-s-font-weight: 400;
$paragraph-s-color: #000000;

$paragraph-l-font-size: 1.25rem;
$paragraph-l-line-height: 2rem;
$paragraph-l-font-family: 'Roboto', 'sans-serif';
$paragraph-l-font-weight: 400;
$paragraph-l-color: #000000;

$hero-s-font-size: 1.5rem;
$hero-s-line-height: 2.0999999046325684rem;
$hero-s-font-family: 'GT Walsheim Pro', 'sans-serif';
$hero-s-font-weight: 900;
$hero-s-color: #000000;

$hero-m-font-size: 2.25rem;
$hero-m-line-height: 2.63671875rem;
$hero-m-font-family: 'GT Walsheim Pro', 'sans-serif';
$hero-m-font-weight: 900;
$hero-m-color: #000000;

$hero-l-font-size: 3.5rem;
$hero-l-line-height: 4.1015625rem;
$hero-l-font-family: 'GT Walsheim Pro', 'sans-serif';
$hero-l-font-weight: 900;
$hero-l-color: #000000;

$headline-l-font-size: 2.625rem;
$headline-l-line-height: 3.4124999046325684rem;
$headline-l-font-family: 'GT Walsheim Pro', 'sans-serif';
$headline-l-font-weight: 700;
$headline-l-color: #000000;

$headline-m-font-size: 2.25rem;
$headline-m-line-height: 2.924999952316284rem;
$headline-m-font-family: 'GT Walsheim Pro', 'sans-serif';
$headline-m-font-weight: 700;
$headline-m-color: #000000;

$headline-s-font-size: 2rem;
$headline-s-line-height: 2.5999999046325684rem;
$headline-s-font-family: 'GT Walsheim Pro', 'sans-serif';
$headline-s-font-weight: 700;
$headline-s-color: #000000;

$headline-xl-font-size: 3rem;
$headline-xl-line-height: 3.8999998569488525rem;
$headline-xl-font-family: 'GT Walsheim Pro', 'sans-serif';
$headline-xl-font-weight: 700;
$headline-xl-color: #000000;

$headline-xxs-font-size: 1.5rem;
$headline-xxs-line-height: 1.9499999284744263rem;
$headline-xxs-font-family: 'GT Walsheim Pro', 'sans-serif';
$headline-xxs-font-weight: 700;
$headline-xxs-color: #000000;

$headline-xs-font-size: 1.75rem;
$headline-xs-line-height: 2.2749998569488525rem;
$headline-xs-font-family: 'GT Walsheim Pro', 'sans-serif';
$headline-xs-font-weight: 700;
$headline-xs-color: #000000;

$headline-xxxs-font-size: 1.25rem;
$headline-xxxs-line-height: 1.625rem;
$headline-xxxs-font-family: 'GT Walsheim Pro', 'sans-serif';
$headline-xxxs-font-weight: 700;
$headline-xxxs-color: #000000;

$positive-800-shade: #194404;
$positive-500-shade-core: #338808;
$positive-050-tint: #f5f9f3;
$positive-700-shade: #245f06;
$positive-300-tint: #85b86b;
$positive-100-tint: #e7f1e1;
$positive-600-shade: #2c7607;
$positive-900-shade: #0e2602;
$positive-400-tint: #5ca039;
$positive-200-tint: #b8d5a9;

$transparency-white-500: rgba(255, 255, 255, 0.5);
$transparency-white-900: rgba(255, 255, 255, 0.9);
$transparency-white-800: rgba(255, 255, 255, 0.8);
$transparency-white-700: rgba(255, 255, 255, 0.7);
$transparency-white-600: rgba(255, 255, 255, 0.6);
$transparency-white-400: rgba(255, 255, 255, 0.4);
$transparency-white-300: rgba(255, 255, 255, 0.3);
$transparency-white-200: rgba(255, 255, 255, 0.2);
$transparency-white-100: rgba(255, 255, 255, 0.1);
$transparency-white-050: rgba(255, 255, 255, 0.05);

$transparency-black-500: rgba(0, 0, 0, 0.5);
$transparency-black-600: rgba(0, 0, 0, 0.6);
$transparency-black-100: rgba(0, 0, 0, 0.1);
$transparency-black-700: rgba(0, 0, 0, 0.7);
$transparency-black-400: rgba(0, 0, 0, 0.4);
$transparency-black-300: rgba(0, 0, 0, 0.3);
$transparency-black-900: rgba(0, 0, 0, 0.9);
$transparency-black-800: rgba(0, 0, 0, 0.8);
$transparency-black-200: rgba(0, 0, 0, 0.2);
$transparency-black-050: rgba(0, 0, 0, 0.05);

$attention-200-tint: #f8cda6;
$attention-800-shade: #753800;
$attention-600-tint: #bb5a00;
$attention-700-shade: #a44f00;
$attention-050-tint: #fef8f2;
$attention-400-tint: #ef8d33;
$attention-300-tint: #f3aa66;
$attention-100-tint: #fdeee0;
$attention-900-shade: #422000;
$attention-500-tint-core: #eb7100;

$critical-200-tint: #f4b7b4;
$critical-300-tint: #ed847e;
$critical-800-shade: #701914;
$critical-900-shade: #3f0e0b;
$critical-700-shade: #9d231b;
$critical-600-shade: #c32b22;
$critical-500-shade-core: #e03127;
$critical-400-tint: #e65b52;
$critical-100-tint: #fbe6e5;
$critical-050-tint: #fdf5f4;

$guidance-200-tint: #a6d2de;
$guidance-050-tint: #f2f9fa;
$guidance-400-tint: #3498b4;
$guidance-900-shade: #00232d;
$guidance-500-shade-core: #017ea1;
$guidance-800-shade: #003f50;
$guidance-700-shade: #015871;
$guidance-300-tint: #67b2c7;
$guidance-600-shade: #016e8c;
$guidance-100-tint: #e1f0f4;

$neutrals-300-tint: #989696;
$neutrals-700-shade: #3a3838;
$neutrals-400-shade: #757373;
$neutrals-900-shade: #171616;
$neutrals-800-shade: #292828;
$neutrals-200-tint: #c3c2c2;
$neutrals-100-tint: #eaeaea;
$neutrals-white: #ffffff;
$neutrals-black: #000000;
$neutrals-500-core: #535050;
$neutrals-600-shade: #484646;
$neutrals-050-tint: #f6f6f6;

$effects-transparent: #ffffff;

$support-3-500-core-shade: #801f3d;
$support-3-600-shade: #6f1b35;
$support-3-900-shade: #240911;
$support-3-800-shade: #400f1e;
$support-3-400-shade: #994c64;
$support-3-050-tint: #f9f4f5;
$support-3-700-shade: #5a162b;
$support-3-300-shade: #b3798b;
$support-3-200-tint: #d3b1bb;
$support-3-100-tint: #f0e4e8;

$support-2-050-tint: #fbfcfd;
$support-2-200-tint: #e0e9ef;
$support-2-600-tint: #91a8b7;
$support-2-400-tint: #b9cddb;
$support-2-500-core-tint: #a7c1d2;
$support-2-700-tint: #758793;
$support-2-100-tint: #f4f8fa;
$support-2-900-shade: #2f363b;
$support-2-300-tint: #cadae4;
$support-2-800-shade: #536069;

$support-1-100-tint: #fdf2df;
$support-1-500-core-tint: #f7cb80;
$support-1-700-tint: #ad8e5a;
$support-1-900-shade: #453924;
$support-1-800-shade: #7b6540;
$support-1-600-tint: #d7b16f;
$support-1-400-tint: #f8d393;
$support-1-300-tint: #f9d8a0;
$support-1-200-tint: #fbe5bf;
$support-1-050-tint: #fef9f0;

$secondary-400-tint: #a7a1a3;
$secondary-900-shade: #302322;
$secondary-700-shade: #544a4c;
$secondary-300-tint: #bdb9ba;
$secondary-600-shade: #756d6f;
$secondary-500-core-tint: #979092;
$secondary-200-tint: #d9d6d7;
$secondary-800-shade: #3e3435;
$secondary-100-tint: #f2f1f1;
$secondary-050-tint: #faf9f9;

$primary-100-tint: #fbe6e5;
$primary-200-tint: #f4b7b4;
$primary-500-shade-core: #e03127;
$primary-600-shade: #c32b22;
$primary-400-tint: #e65b52;
$primary-800-shade: #701914;
$primary-700-shade: #9d231b;
$primary-900-shade: #3f0e0b;
$primary-300-tint: #ed847e;
$primary-050-tint: #fdf5f4;

// Static section, not fetched from Figma
$screen-s: 480px;
$screen-m: 1200px;
$spacing: 8px;
$mobile-edge: 16px;
$grid-narrow: 540px;
$grid-wide: 1140px;
