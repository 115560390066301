@import 'src/variables';
@import '/src/app/component-library-wave/designtokens';

.header {
  text-align: center;
  margin-bottom: $spacing * 2;

  @media (min-width: $screen-md) {
    margin-bottom: $spacing * 4;
  }
}

.card {
  border: solid 1px $secondary-200-tint;
  border-radius: $border-radius;
  padding: $spacing * 2;

  @media (min-width: $screen-sm) {
    padding: $spacing * 4;
  }
}

.cardContainer {
  & > div {
    border-bottom: 1px solid $secondary-200-tint;
    margin-bottom: $spacing * 2;

    &:last-of-type {
      border-bottom: 0;
      margin-bottom: 0;
    }
  }
}
