@import 'variables';
@import 'src/app/component-library-wave/designtokens';

.relocationReceiptContainer {
  align-items: center;
  display: flex;
  flex-direction: column;

  .submitIcon {
    height: $spacing * 11;
    margin-bottom: $spacing * 5;
    margin-top: $spacing * 11;
    width: $spacing * 11;
  }

  .heading {
    margin-bottom: $spacing * 3;
  }

  .ingress {
    margin-bottom: $spacing * 8;
  }

  .toFrontPageLink {
    display: flex;
    align-items: center;
    margin-bottom: 123px;
    padding: 11px ($spacing * 2);
    text-decoration: none;
    color: $secondary-900;
    background: transparent;
    border: 2px solid $secondary-900;
    border-radius: 4px;

    .arrow {
      fill: $secondary-900-shade;
      margin-right: $spacing;
      @media (min-width: $screen-md) {
        margin: 0 12px 0 0;
      }
    }

    &:focus {
      box-shadow:
        -3px -3px 0 0 $guidance-500-core,
        3px -3px 0 0 $guidance-500-core,
        -3px 3px 0 0 $guidance-500-core,
        3px 3px 0 0 $guidance-500-core;
    }

    &:active {
      border: 2px solid $secondary-600;
      color: $secondary-300;
    }

    &:hover {
      .arrow,
      .linkText {
        fill: $secondary-400;
        color: $secondary-400;
      }
    }
  }
}
