@import 'variables';

.enterpriseServicesContainer {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.enterpriseServiceContainer {
  display: flex;
  align-items: center;
  width: 48%;
  margin: 10px 0;

  svg {
    margin-right: $spacing;
  }
}

@media (width <= 600px) {
  .enterpriseServiceContainer {
    width: 100%;
  }
}

.container {
  display: flex;
  align-items: center;
  place-content: stretch flex-start;

  svg {
    margin-right: $spacing;
  }
}

.addressContainer,
.container:not(:last-child) {
  margin-bottom: $spacing * 2;
}

.addressContainer {
  display: flex;
  flex-direction: column;

  .row {
    display: flex;
    align-items: center;
  }

  .addressRow {
    margin-left: $spacing * 6;
  }
}

.enterpriseServiceIcon {
  fill: $secondary-900;
  stroke: none;
}

.circuitIdContainer {
  display: flex;
  flex-direction: row;
  margin-left: auto;
}

.wifi {
  stroke: $secondary-400;
}

.tv {
  fill: $secondary-400;
}

.house {
  height: #{calc(32 / 16)}rem;
  width: #{calc(32 / 16)}rem;
}

.serviceName {
  margin-left: $spacing * 2;
}

.serviceIcon {
  margin-right: $spacing;
  margin-left: 2px;
}

.title {
  margin-left: $spacing * 2;
  flex-basis: max-content;
  margin-bottom: 0;
}
