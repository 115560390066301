@import 'src/variables';
@import 'src/app/component-library-wave/designtokens';

.card,
.buttons,
.clusterContainer {
  @media (min-width: $screen-md) {
    flex: 1 1 40%;
  }
}

.cardContainer {
  min-width: $grid-narrow;
}

.clusterContainer {
  display: flex;
  flex-flow: column nowrap;
  row-gap: $spacing * 3;
}

.upnp,
.ipv6Card {
  height: fit-content;
  padding-bottom: $spacing * 2;
}

.ipv6 {
  word-break: break-all;
}

.formContainer {
  display: flex;
  flex-direction: column;
  row-gap: $spacing * 3;

  @media (min-width: $screen-md) {
    height: 50%;
    display: flex;
    column-gap: $spacing * 6;
    flex-flow: wrap;
  }
}

.helpNotice {
  margin-bottom: $spacing * 4;
  border: none;

  @media (min-width: $screen-md) {
    border-bottom: inherit;
  }
}

.helperLabel {
  margin-bottom: $spacing * 2;
}

.ipAddressLabel {
  margin-bottom: $spacing * 2;
  @media (min-width: $screen-md) {
    margin-bottom: $spacing * 4;
  }
}

.checkboxHelperAlert {
  margin-bottom: 0;
  padding-right: 25px;
  margin-right: -25px;
  position: relative;
}

.alertParagraph {
  margin-top: $spacing;
  margin-bottom: $spacing * 4;

  @media (min-width: $screen-sm) {
    margin-top: 0.5 * $spacing;
  }
}

.leaseTimeSelect {
  // 😥
  > div > select {
    box-shadow:
      1px 1px 1px rgb(0 0 0 / 10%),
      0 1px 2px rgb(0 0 0 / 15%),
      1px 1px 4px rgb(0 0 0 / 25%),
      0 2px 8px rgb(0 0 0 / 10%);
    border-radius: 2px;
  }
}

.ipAddress {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: $spacing;
  margin-bottom: $spacing * 2;
  @media (min-width: $screen-md) {
    margin-bottom: $spacing * 3;
  }

  .smallInputField {
    label {
      display: none;
    }

    ::before {
      content: '.';
      font-weight: $text-l-medium-font-weight;
      font-size: $text-l-medium-font-size;
      line-height: $text-xl-medium-line-height;
      margin-left: $spacing;
      margin-right: $spacing;
    }

    div {
      margin-bottom: 0;
    }
    flex: 1;
    min-width: 4.3125rem;
    max-width: 5.4rem;
  }
}

.dhcpFirstLabel,
.dhcpLastLabel {
  display: inline-block;
  margin-bottom: $spacing * 2;
}

.buttonRow {
  display: flex;
  justify-content: center;
  grid-area: buttons;

  .saveButton {
    @media (min-width: $screen-md) {
      margin-right: 0;
    }
  }

  @media (min-width: $screen-md) {
    justify-content: flex-end;
    margin-top: $spacing;
  }
}
