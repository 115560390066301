@import 'src/variables';
@import 'src/app/component-library-wave/designtokens';

.heading {
  margin-bottom: $spacing * 2;
}

.heading1 {
  margin-bottom: $spacing * 2;
}

.heading2 {
  margin-bottom: $spacing * 4;
}

.helpTextContainer {
  display: flex;
  align-items: center;
  margin-bottom: $spacing * 2;
  @media (min-width: $screen-md) {
    margin-bottom: 0;
  }

  .rulesExceededAlertContainer {
    display: flex;
    align-items: center;
  }
}

.alertWarningIcon {
  margin-right: $spacing;
}

.section {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: $spacing * 3 $spacing * 5;
  @media (min-width: $screen-md) {
    grid-template-columns: 1fr 1fr;
  }
}

.rulesSection {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: $spacing * 3;
}

.ruleContainer {
  flex-basis: 100%;
  @media (min-width: $screen-md) {
    flex-basis: 48%;
  }
}

.buttonContainer {
  display: flex;
  flex: 0 auto;
}

.firewallContainer {
  margin: $mobile-edge;
  @media (min-width: $screen-md) {
    margin: $spacing * 4;
  }
}

.createRuleButton {
  flex-grow: 1;
  margin: 0;
  @media (min-width: $screen-md) {
    flex-grow: 0;
  }
}

.description {
  letter-spacing: 0.005em;
  line-height: $paragraph-m-line-height;
  margin-bottom: $spacing * 4;
}

.moreInfoLink {
  border-bottom: solid;
  text-decoration: none;
}

.introduction {
  margin: $mobile-edge;
  margin-bottom: 0;
  @media (min-width: $screen-md) {
    margin: $spacing * 4;
  }
}

.introductionContainer {
  margin: 0;
  padding-bottom: $spacing;
}

.toast {
  margin-bottom: $spacing * 8;
}

.rulesSummary {
  padding: 0;
  margin: 0 $mobile-edge $spacing * 4 $mobile-edge;
  @media (min-width: $screen-md) {
    margin: $spacing * 4;
  }
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.hideOnDesktop {
  @media (min-width: $screen-md) {
    display: none;
  }
}

.separator {
  margin-right: $spacing * 0.5;
  margin-left: $spacing * 0.5;

  @media (min-width: $screen-sm) {
    margin-right: $spacing;
    margin-left: $spacing;
  }

  &::after {
    content: '.';
  }
}
