@import 'src/variables';
@import 'src/app/component-library-wave/designtokens';

.textWithButton {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: $spacing * 2;

  .paddingRight {
    padding-right: $spacing * 2.5;
  }

  .helpButton {
    background: none;
    border: 0;
    padding: 0;
    height: 1.5rem;

    &:hover {
      cursor: pointer;
    }
  }

  @media (min-width: $screen-md) {
    justify-content: flex-start;
  }
}

.alert {
  margin-top: $spacing * 2.5;
}

.fillBlue {
  fill: $guidance-600-shade;
}

.strokeBlue {
  stroke: $guidance-600-shade;
}
