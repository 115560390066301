@import 'variables';
@import 'src/app/component-library-wave/designtokens';

.greeting {
  word-wrap: break-word;
}

.welcome {
  margin-bottom: $spacing * 5;
  text-align: center;
  @media (min-width: $screen-md) {
    margin-top: ($spacing * 5);
  }

  @media (min-width: $screen-m) {
    margin-top: ($spacing * 7);
  }
}

.locationContainer {
  margin-bottom: 6 * $spacing;
}
