@import 'variables';

.criteriaContainer {
  display: flex;
  align-items: center;
  margin-bottom: $spacing;

  .icon {
    width: 24px;
    min-width: 24px;
    height: 24px;
    vertical-align: bottom;
    margin-right: $spacing;
  }

  .criteria {
    display: inline;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}
