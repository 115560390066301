@import 'variables';

.introduction {
  margin-bottom: $spacing * 6;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: $screen-md) {
    margin-bottom: $spacing * 8;
  }
}

.headline {
  margin-bottom: $spacing * 4;
}

.paragraph {
  margin-bottom: $spacing * 3;

  &:last-child {
    margin-bottom: 0;
  }
}

.date {
  margin-bottom: $spacing * 2;

  @media (min-width: $screen-md) {
    margin-bottom: $spacing * 3;
  }
}

.plannedConnectionContainer {
  margin-top: $spacing * 6;
}
