@import 'variables';
@import 'src/app/component-library-wave/designtokens';

.container {
  background: $neutral-0;
  box-sizing: border-box;
  width: 100%;
  overflow-x: auto;
  margin-bottom: $spacing;
}

.toggleContainer,
.buttonContainer,
.formContainer {
  padding-top: $spacing * 3;
  padding-left: $spacing * 3;
  padding-right: $spacing * 3;
  border: $border;
  flex: 1;
  margin-bottom: 0;
}

.toggleContainer {
  border-bottom: none;
  border-radius: $border-radius $border-radius 0 0;
}

.formContainer {
  border-bottom: none;
  border-radius: 0;
}

.buttonContainer {
  border-top: none;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  padding-bottom: $spacing * 3;
}

.hideBorder {
  border: none;
}

.hideBorderToggle {
  border: none;
  border-bottom: $border;
}

.multipleContainer {
  width: 100%;
}

.combinedContainer {
  width: 100%;
}

.networkHeadline {
  text-align: center;
}

.arrow {
  stroke: $secondary-900-shade;
  margin-left: $spacing * 1.5;
  vertical-align: top;
}

.moreSettings {
  text-align: center;
  margin-bottom: $spacing * 7;
}

.toast {
  margin-bottom: $spacing * 8;
}

.ssidList {
  list-style-type: none;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: $spacing * 2;

  @media (min-width: $screen-md) {
    column-gap: $spacing * 4;
    flex-direction: row;
  }
}

.singleBand {
  @media (min-width: $screen-md) {
    display: block;
  }
}

.devices {
  margin-top: $spacing * 7;
  margin-bottom: $spacing * 9;
}

.button {
  margin-left: 0;
}
