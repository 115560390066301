@import 'variables';

.inactiveWarningBanner {
  margin-top: $spacing * -5;
  margin-bottom: $spacing * 5;
}

.heading {
  margin-bottom: $spacing * 2;
}

.generalEmailInformation {
  margin-bottom: $spacing * 8;
}

.paragraph {
  margin-bottom: $spacing * 2;
}
