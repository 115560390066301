@import 'variables';
@import 'src/app/component-library-wave/designtokens';

.wifiPlussImageContainer {
  display: grid;
  margin-bottom: $spacing * 5;
  grid-template-areas:
    'header'
    'image'
    'description';

  @media (min-width: $screen-md) {
    margin-left: $spacing * 4;
    grid-template-columns: 50%;
    grid-template-areas:
      '. image'
      'header image '
      'description image'
      '. image';
    flex-direction: row;
    margin-bottom: $spacing * 3;
  }
}

.wifiPlussHeader {
  grid-area: header;
  margin-block: 0 $spacing * 2;
  font-family: $hero-s-font-family;
  font-size: $headline4-sm-font-size;
  @media (min-width: $screen-md) {
    font-size: $headline2-md-font-size;
  }
}

.wifiPlussImage {
  grid-area: image;
  border: $border;
  border-radius: $spacing;
  width: 100%;
  padding: $spacing;

  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    max-height: 360px;
  }

  @media (min-width: $screen-md) {
    padding: initial;
    border: none;
  }
}

.combinedContainer {
  display: grid;
  grid-template-areas:
    'header'
    'description'
    'toggle';

  @media (min-width: $screen-md) {
    grid-template-columns: 1fr auto;
    grid-template-areas:
      'header toggle'
      'description description';
  }
}

.combinedContainerHelper,
.combinedContainerHelperExpanded {
  > div > h3 {
    font-size: $headline6-md-font-size;
  }
  @media (min-width: $screen-md) {
    > div {
      margin-bottom: 0;
      align-items: center;
    }

    > div > h3 {
      font-size: $headline-xxs-font-size;
    }
  }
}

.containerHelper,
.containerHelperExpanded {
  > div > h3 {
    font-size: $headline6-md-font-size;
  }
  @media (min-width: $screen-md) {
    > div {
      align-items: center;
      margin-bottom: 0;
    }
  }
}

.containerHelperExpanded {
  grid-area: header;
}

.combinedContainerHelperExpanded {
  grid-area: header;
}

.helpTextWrapperSameSettings {
  grid-area: description;

  > div {
    margin-bottom: $spacing * 3;
  }

  @media (max-width: $screen-sm) {
    hyphens: auto;
  }
  @media (min-width: $screen-md) {
    > div {
      margin-bottom: $spacing * 2;
    }
  }
}

.helpTextWrapper {
  grid-area: description;

  > div {
    margin-bottom: $spacing * 3;
  }
  @media (min-width: $screen-md) {
    > div {
      margin-bottom: $spacing * 2;
    }
  }
}

.wifiPlussHeaderDescription {
  margin-top: $spacing * 2;
  grid-area: description;
  line-height: $spacing * 3;
  font-size: $headline6-sm-font-size;
  @media (min-width: $screen-md) {
    margin-top: 0;
    line-height: $spacing * 4;
    font-size: $headline6-lg-font-size;
  }
}

.accessPointContainer,
.itemContainer {
  background: $neutral-0;
  border: $border;
  box-sizing: border-box;
  border-radius: $spacing;
  overflow-x: auto;
}

.accessPointContainer {
  margin-bottom: $spacing * 2;
  padding: $spacing $spacing * 2 $spacing * 2;

  @media (min-width: $screen-md) {
    padding: $spacing * 4;
  }
}

.nameSection {
  padding: $spacing * 4;
}

.buttonSave {
  margin-left: 0;
  margin-top: $spacing * 2;
}

.spinner {
  margin-left: 0;
}

.infoIcon {
  max-width: 24px;
  max-height: 24px;
}

.helpText {
  margin-top: 2px;
  margin-left: $spacing;
  color: $neutrals-800-shade;
  flex-shrink: 2;
}

.combineToggle {
  margin: $spacing 0;

  label {
    font-size: larger;
  }

  > div {
    padding: 0;
  }
}

.toggleWrapper {
  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0;
    padding: 0;

    > label {
      margin-bottom: $spacing * 3;
      font-size: $uiText-md-font-size;
    }

    > div > div {
      margin-left: 0;
    }

    @media (min-width: $screen-md) {
      flex-direction: row;
      align-items: center;

      > label {
        margin-bottom: 0;
        font-size: $headline6-lg-font-size;
      }

      > div > div {
        margin-left: $spacing * 3;
        margin-top: 0;
      }
    }
  }
}

.deviceContainer {
  display: flex;
  flex-direction: column;
  row-gap: $spacing * 2;

  > div:first-child {
    margin-top: $spacing * 2;
  }
}

.accessPointToggleWrapper {
  margin: $spacing * 4;
  margin-right: $spacing * 3;
  padding: 0;

  @media (min-width: $screen-md) {
    margin-left: $spacing * 3;
  }

  > div > label {
    font-size: $text-l-medium-font-size;
    line-height: $text-l-medium-line-height;
    font-family: $text-l-medium-font-family;
    font-weight: $text-l-medium-font-weight;
  }
}

.accessPointCombinedWrapper {
  padding: 0;
}

.iconContainer {
  margin: $spacing * 2 0;
  display: flex;
  align-items: center;
}
