@import '/src/app/component-library-wave/designtokens.scss';
@import '../typography.mixins';

@include font-base;

@include mobile-fonts;

// Tablet
@media (min-width: $screen-s) {
  @include tablet-fonts;
}
