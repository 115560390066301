@import 'variables';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto ($spacing * 9);
  text-align: center;
}

.locationList {
  padding: 0;
  margin: 0;
  width: 100%;
}

.locationListItem {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: $spacing * 3;
  }
}

.partnerTitle {
  margin-bottom: 2 * $spacing;
  text-align: left;
}

.partnerContainer {
  width: 100%;
  margin-bottom: 0;
}

.customerTitle {
  margin-bottom: 2 * $spacing;
  text-align: left;
}

.customerContainer {
  width: 100%;
  margin-bottom: 7 * $spacing;
}

// Large screens
@media (min-width: $screen-md) {
  .locationList {
    padding: 0;
  }

  .partnerTitle {
    margin-bottom: 3 * $spacing;
  }

  .partnerContainer {
    width: fit-content;
    margin-bottom: $spacing;
  }

  .customerTitle {
    margin-bottom: 3 * $spacing;
  }

  .customerContainer {
    width: fit-content;
    margin-bottom: 8 * $spacing;
  }
}
