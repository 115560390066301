@import 'src/variables';
@import 'src/app/component-library-wave/designtokens';

.movieRentalHistoryPage {
  margin-bottom: $spacing * 8;

  @media (min-width: $screen-s) {
    margin-left: auto;
    margin-right: auto;
  }

  .information {
    margin: 0 $mobile-edge * 2 $spacing * 7;
    @media (min-width: $screen-s) {
      margin-left: $mobile-edge;
      margin-right: $mobile-edge;
    }
    @media (min-width: $screen-md) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .informationText {
    margin-top: $spacing * 2;
    margin-bottom: $spacing * 7;
  }

  .empty {
    padding: $spacing * 3;
    background-color: $neutrals-050-tint;
  }

  .historyData {
    margin: 0 $mobile-edge $spacing;
    @media (min-width: $screen-md) {
      margin: 0;
    }
  }

  .filterTable {
    display: flex;
    flex-direction: column;
  }

  .headerRow {
    padding: $spacing $spacing * 3;
    justify-content: space-between;
    display: flex;
    border-bottom: 1px solid $black;
    font-weight: bold;
  }

  .filterRow {
    padding: $spacing * 2;
    justify-content: space-between;
    display: flex;
    margin-bottom: $spacing;
  }

  ul > .filterRow:nth-child(even) {
    background-color: $neutrals-050-tint;
  }

  .item {
    margin: 0 $spacing * 2;
  }
}
