@import 'variables';

.tabs {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  margin-bottom: $spacing * 4;
  display: flex;
  overflow-x: auto;

  button:first-child {
    margin-left: 2px;
  }
  @media (min-width: $screen-md) {
    margin-left: $spacing * 4;
  }

  .tab {
    padding: $spacing * 2;
    margin: 2px 0;
    background: none;
    border: 0;
    border-bottom: 2px solid $formds-common-black-180;
    white-space: nowrap;

    @media (min-width: $screen-md) {
      padding: $spacing * 2 $spacing * 3;
    }

    &:hover {
      cursor: pointer;
      background-color: $formds-altibox-secondary-190;
    }

    &:focus-visible {
      outline: 2px solid $formds-common-blue-100;
      border: 0;
      border-bottom: 2px solid transparent;
    }
  }

  .selected {
    border-bottom: 2px solid $formds-common-blue-100;
  }
}

/* hide horizontal scrollbar Sfari,Chrome and Opera */
.tabs::-webkit-scrollbar {
  display: none;
}
