@import '/src/app/component-library-wave/designtokens.scss';
@import '../input.mixins';

@include base-input;

// Tablet
@media (min-width: $screen-s) and (max-width: $screen-m - 1) {
  @include tablet-input;
}
// Desktop
@media (min-width: $screen-m) {
  @include desktop-input;
}
