@import 'variables';

.welcomePage {
  margin: ($spacing * 12) auto 0;
  overflow: hidden;
  padding: 0 ($spacing * 4);

  .heading {
    margin-bottom: $spacing * 5;
    margin-top: $spacing;
    text-align: center;
  }

  .locationContainer {
    margin-bottom: 6 * $spacing;
    @media (min-width: $screen-md) {
      margin-bottom: 5 * $spacing;
    }
  }

  .ingress {
    margin-bottom: $spacing * 12;
    text-align: center;

    @media (min-width: $screen-md) {
      margin: 0 ($spacing * 12) ($spacing * 8);
    }
  }

  .illustrations {
    display: flex;
    flex-direction: column;
    margin-bottom: $spacing * 12;
    position: relative;

    @media (min-width: $screen-md) {
      flex-direction: row;
    }

    .column {
      margin-bottom: $spacing * 6;

      @media (min-width: $screen-md) {
        margin-right: $spacing * 24;
      }

      &.contentAndSettings,
      &.productsAndUsage,
      &.profileAndPerso {
        display: none;

        @media (min-width: $screen-md) {
          display: block;
        }
      }

      &.wifiAnd,
      &.handWidthPencil {
        display: block;

        @media (min-width: $screen-md) {
          display: none;
        }
      }

      &.handWithPencil {
        margin-right: 0;
        position: absolute;
        right: -215px;
        top: -10px;
        transform: rotate(-25deg);

        @media (min-width: $screen-md) {
          right: -150px;
          top: -10px;
          transform: rotate(-15deg);
        }
      }
    }
  }

  .orderTracker {
    margin-top: 5 * $spacing;
  }

  .linkSection {
    margin-top: 5 * $spacing;
  }
}
