@import '/src/app/component-library-wave/designtokens';
@import 'src/variables';

.formContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 $mobile-edge;
  row-gap: $spacing * 2;
  @media (min-width: $screen-md) {
    row-gap: $spacing * 4;
    margin: 0 $spacing * 4;
  }
}

.heading {
  min-width: 100%;
}

.card {
  flex-basis: 100%;
  margin: 0;
  @media (min-width: $screen-md) {
    flex-basis: 48%;
  }
}

.name {
  margin: $spacing 0;
  @media (min-width: $screen-md) {
    margin: $spacing * 2 0;
  }
}

.errorName {
  margin-bottom: $spacing * 3;
}

.ipAddressHeading {
  margin-bottom: $spacing * 2;
  @media (min-width: $screen-md) {
    margin-bottom: $spacing * 3;
  }
}

.ipAddress {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: $spacing * 2;
  @media (min-width: $screen-md) {
    margin-bottom: $spacing * 3;
  }

  .smallInputField {
    flex: 1;
    min-width: 4.3125rem;
    max-width: 5.4rem;

    label {
      display: none;
    }

    ::before {
      content: '.';
      font-weight: $text-l-medium-font-weight;
      font-size: $text-l-medium-font-size;
      line-height: $text-xl-medium-line-height;
      margin-left: $spacing;
      margin-right: $spacing;
    }

    div {
      margin-bottom: 0;
    }
  }
}

.errorLabel {
  color: $attention-700-shade;
}

.optional {
  margin-left: $spacing * 2;
}

.port {
  margin-top: $spacing * 3;
}

.number {
  margin-top: $spacing * 1.25;
}

.portsHeading {
  margin-bottom: $spacing * 3;
}

.portFieldsRow {
  display: flex;
  flex-direction: column;
  row-gap: $spacing * 4;

  @media (min-width: $screen-md) {
    flex-direction: row;
    column-gap: $spacing * 8;

    input {
      max-width: 8.75rem;
    }
  }
}

.internalPortToLabel,
.externalPortToLabel {
  margin-bottom: $spacing;
  display: inline-block;
}

.helpTextContainer {
  display: flex;

  .iconContainer {
    margin-right: calc($spacing / 2);

    > svg {
      min-width: 28px;
      min-height: 28px;
    }
  }
}

.infoIcon {
  width: 24px;
  min-width: 24px;
  height: 21px;
}

.ipSeparator {
  margin-right: $spacing * 0.5;
  margin-left: $spacing * 0.5;

  @media (min-width: $screen-sm) {
    margin-right: $spacing;
    margin-left: $spacing;
  }

  &::after {
    content: '.';
  }
}

.errorPort {
  margin-top: $spacing * 2;
}

.buttonContainer {
  min-width: 100%;
}

.buttonRow {
  display: flex;
  justify-content: center;

  .saveButton {
    @media (min-width: $screen-md) {
      margin-right: 0;
    }
  }

  @media (min-width: $screen-md) {
    justify-content: flex-end;
    margin: 0 0 144px;
  }
}
