@import 'variables';

.list {
  list-style: disc;
  padding-left: $spacing * 3;
  margin-bottom: $spacing * 3;

  .item {
    font-size: 1.125rem;
    line-height: 1.6;
    letter-spacing: 0.5;
    margin-bottom: $spacing * 2;
  }
}
