@import 'variables';

.dynamicCard {
  border-radius: $border-radius;
  margin: 0 ($spacing * 2) ($spacing * 6);
  padding: ($spacing * 3) ($spacing * 2);

  @media (min-width: $screen-md) {
    padding: ($spacing * 6) ($spacing * 6) ($spacing * 2);
  }

  &.step1Background {
    background-color: $support-3-100;
  }

  &.step2Background {
    background-color: $support-1-100;
  }

  &.step3Background {
    background-color: $support-2-200;
  }

  &.step4Background {
    background-color: $primary-100;
  }

  &.step5Background {
    background-color: #e7f1e1;
  }
}
