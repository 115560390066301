@import 'variables';
@import 'src/app/component-library-wave/designtokens';

.heading {
  margin-bottom: $spacing * 2;
}

.emailAccountList {
  padding: 0;
  margin-bottom: $spacing * 8;

  .emailAccount {
    list-style: none;
    margin-bottom: $spacing * 3;

    .emailAccountContainer {
      border: 1px solid $secondary-200-tint;
      border-radius: $border-radius;
      padding: $mobile-edge;
      @media (min-width: $screen-md) {
        padding: $spacing * 3;
      }

      .emailAddress {
        margin-bottom: $spacing;
        word-break: break-word;
      }

      .terminationDate {
        margin-bottom: $spacing * 3;
      }

      .buttonContainer {
        display: flex;
      }

      .deleteAccountButton {
        margin-left: 0;
      }

      .emailAccountCanNotBeActivated {
        margin-top: $spacing * 3;
      }
    }
  }
}
