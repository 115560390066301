@import 'src/variables';

.alertBox {
  margin-bottom: $spacing * 4;

  div:nth-child(2) {
    padding: 0 $spacing * 5 $spacing * 3 $spacing * 5;
    @media (min-width: $screen-md) {
      padding: 0 $spacing * 6 $spacing * 2 $spacing * 11;
    }
  }
}

.alertText {
  display: list-item;
}
