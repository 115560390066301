@import 'variables';
@import 'src/app/component-library-wave/designtokens';

.formHeader {
  margin-bottom: $spacing * 2;
}

.form {
  display: flex;
  flex-direction: column;
  row-gap: $spacing * 3;
}

.list {
  dt {
    font-size: $text-m-bold-font-size;
    font-weight: $text-m-bold-font-weight;
    font-family: $text-m-bold-font-family;
    line-height: $text-m-bold-line-height;
  }

  dd {
    margin-inline-start: 0;
    margin-top: $spacing * 2;
    margin-bottom: $spacing * 3;
    font-size: $text-xl-regular-font-size;
    font-weight: $text-xl-regular-font-weight;
    font-family: $text-xl-regular-font-family;
    line-height: $text-xl-regular-line-height;
  }
}

.selectInputLabel label {
  display: none;
}

.selectInputHelper {
  margin-bottom: $spacing;
}

.informationText {
  display: flex;
  flex-direction: column;
  row-gap: $spacing;
}

.helpText {
  margin-top: 2px;
  margin-left: $spacing;
  color: $neutrals-800-shade;

  &.disabled {
    color: $neutrals-300-tint;
  }
}

.helpTextContainer {
  display: flex;
  align-items: center;
  flex: 36px auto;

  .iconContainer {
    display: flex;
    align-items: center;
  }
}

.inputLabel {
  > label > div {
    font-size: $paragraph-md-font-size;
  }
}

.infoIcon {
  width: 24px;
  height: 24px;

  > svg {
    min-width: 28px;
    min-height: 28px;
  }
}

.warningIcon {
  min-width: 24px;
}

.value {
  font-size: $text-xl-regular-font-size;
  font-weight: $text-xl-regular-font-weight;
}

.buttons {
  display: flex;
  flex: 0 auto;
}

.button {
  margin-bottom: $spacing;
  margin-right: $spacing * 2;
}

.eyeButton {
  border: none;
  background: none;
  position: absolute;
  margin-left: $spacing * 19;

  svg {
    width: auto;
    height: auto;
  }

  :hover {
    cursor: pointer;
  }
}

.buttonCancel {
  margin-left: 0;
  flex-shrink: 0;
}

.buttonSave {
  margin-right: 0;
}

.password {
  display: flex;
  width: $spacing * 8;
}

.csrText {
  line-height: $text-xl-regular-line-height;
}

.isSsidHiddenRadio {
  legend {
    /* stylelint-disable-next-line declaration-no-important */
    margin-bottom: $spacing * 2 !important; /* RadioButton margin doesnt match Input margin */
    font-size: $paragraph-md-font-size;
  }

  > div > div {
    padding-top: $spacing;
    padding-bottom: $spacing;
  }

  /* stylelint-disable */
  input {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  label {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  /* stylelint-enable */

  @media (min-width: $screen-md) {
    width: max-content;
  }
}
