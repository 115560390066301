@import 'src/variables';
@import 'src/app/component-library-wave/designtokens';

.messageContainer {
  display: flex;
  margin: 0 $spacing * 2 $spacing * 6 $spacing * 2;
  flex-direction: column;
  align-items: flex-start;
  gap: $spacing * 2;
  @media (min-width: $screen-md) {
    display: flex;
    padding: $spacing * 6;
    border-radius: $border-radius;
    border: $border;
    background: $white;
    max-width: $navigation-breakpoint-small;
    margin: auto;
    margin-bottom: $spacing * 15;
  }
}

.button {
  display: flex;
  gap: 0;
  align-self: stretch;
  margin-top: $spacing * 2;
  @media (min-width: $screen-md) {
    gap: $spacing * 4;
    margin-top: $spacing * 3;
  }
}

.header {
  line-height: $formds-headline-xs-line-height;
  font-size: $formds-headline-xs-font-size;
  margin-bottom: $spacing * 3;
  @media (min-width: $screen-md) {
    line-height: $formds-headline-m-line-height;
    font-size: $formds-headline-m-font-size;
    margin-bottom: $spacing * 3;
  }
}

.subHeader {
  font-size: $headline6-md-font-size;
  line-height: $formds-paragraph-m-line-height;
  @media (min-width: $screen-md) {
    font-size: $formds-paragraph-xl-font-size;
    line-height: $formds-paragraph-xl-line-height;
  }
}

.subParagraph {
  @media (min-width: $screen-md) {
    line-height: $formds-paragraph-xl-line-height;
  }
}

.portImage {
  width: 100%;
}
