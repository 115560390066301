@import '/src/app/component-library-wave/designtokens.scss';

@mixin xxs-button-base {
  font-size: $text-xxs-bold-font-size;
  line-height: $text-xxs-bold-line-height;
  font-family: $text-xxs-regular-font-family;
  font-weight: $text-xxs-bold-font-weight;
}

@mixin xs-button-base {
  font-size: $text-xs-bold-font-size;
  line-height: $text-xs-bold-line-height;
  font-family: $text-xs-regular-font-family;
  font-weight: $text-xs-bold-font-weight;
}

@mixin s-button-base {
  font-size: $text-s-bold-font-size;
  line-height: $text-s-bold-line-height;
  font-family: $text-s-regular-font-family;
  font-weight: $text-s-bold-font-weight;
}

@mixin m-button-base {
  font-size: $text-m-bold-font-size;
  line-height: $text-m-bold-line-height;
  font-family: $text-m-regular-font-family;
  font-weight: $text-m-bold-font-weight;
}

@mixin l-button-base {
  font-size: $text-l-bold-font-size;
  line-height: $text-l-bold-line-height;
  font-family: $text-l-regular-font-family;
  font-weight: $text-l-bold-font-weight;
}

@mixin underline {
  padding-bottom: 2px;
  border-bottom: 2px solid $secondary-900-shade;
  border-radius: 0;
}

@mixin icon-xxs {
  width: 14px;
  height: 14px;
}

@mixin icon-xs {
  width: 16px;
  height: 16px;
}

@mixin icon-s {
  width: 20px;
  height: 20px;
}

@mixin icon-m {
  width: 24px;
  height: 24px;
}

@mixin icon-l {
  width: 28px;
  height: 28px;
}
