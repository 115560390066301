@import 'variables.scss';
@import './formds-typography-tokens.scss';
@import '/src/app/component-library-wave/designtokens.scss';

@mixin font-base {
  .typography {
    margin: 0;
  }

  .italic {
    font-style: italic;
  }

  .underline {
    text-decoration: underline;
  }
}

@mixin mobile-fonts {
  .formds-common-header-primary {
    font-family: $font-family-roboto;
    font-size: $font-size-7;
    line-height: $line-height-8;
    font-weight: $font-roboto-weight-0;
    letter-spacing: $letter-spacing-3;
    text-wrap: balance;
    &.bold {
      font-weight: $font-roboto-weight-2;
    }
  }
  .formds-common-header-secondary {
    font-family: $font-family-roboto;
    font-size: $font-size-5;
    line-height: $line-height-6;
    font-weight: $font-roboto-weight-0;
    letter-spacing: $letter-spacing-2;
    text-wrap: balance;
    &.bold {
      font-weight: $font-roboto-weight-2;
    }
  }

  .formds-common-header-tertiary {
    font-family: $font-family-roboto;
    font-size: $font-size-4;
    line-height: $line-height-5;
    font-weight: $font-roboto-weight-0;
    letter-spacing: $letter-spacing-1;
    text-wrap: balance;
    &.bold {
      font-weight: $font-roboto-weight-2;
    }
  }

  .formds-common-subtitle-primary {
    font-family: $font-family-roboto;
    font-size: $font-size-2;
    line-height: $line-height-2;
    font-weight: $font-roboto-weight-0;
    letter-spacing: $letter-spacing-1;
    text-wrap: balance;
    &.bold {
      font-weight: $font-roboto-weight-2;
    }
  }

  .formds-common-subtitle-secondary {
    font-family: $font-family-roboto;
    font-size: $font-size-1;
    line-height: $line-height-1;
    font-weight: $font-roboto-weight-0;
    letter-spacing: $letter-spacing-1;
    text-wrap: balance;
    &.bold {
      font-weight: $font-roboto-weight-2;
    }
  }

  .formds-common-subtitle-tertiary {
    font-family: $font-family-roboto;
    font-size: $font-size-1;
    line-height: $line-height-1;
    font-weight: $font-roboto-weight-0;
    letter-spacing: $letter-spacing-1;
    text-wrap: balance;
    &.bold {
      font-weight: $font-roboto-weight-0;
    }
  }

  .formds-altibox-header-primary {
    font-family: $font-family-gt-walsheim;
    font-size: $font-size-7;
    line-height: $line-height-8;
    font-weight: $font-walsheim-weight-0;
    letter-spacing: $letter-spacing-3;
    text-wrap: balance;
    &.bold {
      font-weight: $font-walsheim-weight-2;
    }
  }

  .formds-altibox-header-secondary {
    font-family: $font-family-gt-walsheim;
    font-size: $font-size-5;
    line-height: $line-height-6;
    font-weight: $font-walsheim-weight-0;
    letter-spacing: $letter-spacing-2;
    text-wrap: balance;
    &.bold {
      font-weight: $font-walsheim-weight-2;
    }
  }

  .formds-altibox-header-tertiary {
    font-family: $font-family-gt-walsheim;
    font-size: $font-size-4;
    line-height: $line-height-5;
    font-weight: $font-walsheim-weight-0;
    letter-spacing: $letter-spacing-1;
    text-wrap: balance;
    &.bold {
      font-weight: $font-walsheim-weight-2;
    }
  }

  .formds-altibox-subtitle-primary {
    font-family: $font-family-gt-walsheim;
    font-size: $font-size-2;
    line-height: $line-height-2;
    font-weight: $font-walsheim-weight-0;
    letter-spacing: $letter-spacing-1;
    text-wrap: balance;
    &.bold {
      font-weight: $font-walsheim-weight-2;
    }
  }

  .formds-altibox-subtitle-secondary {
    font-family: $font-family-gt-walsheim;
    font-size: $font-size-1;
    line-height: $line-height-1;
    font-weight: $font-walsheim-weight-0;
    letter-spacing: $letter-spacing-1;
    text-wrap: balance;
    &.bold {
      font-weight: $font-walsheim-weight-2;
    }
  }

  .formds-altibox-subtitle-tertiary {
    font-family: $font-family-gt-walsheim;
    font-size: $font-size-1;
    line-height: $line-height-1;
    font-weight: $font-walsheim-weight-0;
    letter-spacing: $letter-spacing-1;
    text-wrap: balance;
    &.bold {
      font-weight: $font-walsheim-weight-0;
    }
  }

  .formds-lead {
    font-family: $font-family-roboto;
    font-size: $font-size-2;
    line-height: $line-height-2;
    font-weight: $font-roboto-weight-0;
    letter-spacing: $letter-spacing-1;
    &.bold {
      font-weight: $font-roboto-weight-2;
    }
  }

  .formds-body {
    font-family: $font-family-roboto;
    font-size: $font-size-1;
    line-height: $line-height-0;
    font-weight: $font-roboto-weight-0;
    letter-spacing: $letter-spacing-0;
    &.bold {
      font-weight: $font-roboto-weight-2;
    }
  }

  .headline5 {
    font-size: $headline-xxxs-font-size;
    line-height: $headline-xxxs-line-height;
    font-family: $headline-xxxs-font-family;
    font-weight: $headline-xxxs-font-weight;
    color: $secondary-900-shade;
  }

  .headline4 {
    font-size: $headline-xxs-font-size;
    line-height: $headline-xxs-line-height;
    font-family: $headline-xxs-font-family;
    font-weight: $headline-xxs-font-weight;
    color: $secondary-900-shade;
  }

  .headline3 {
    font-size: $headline-xs-font-size;
    line-height: $headline-xs-line-height;
    font-family: $headline-xs-font-family;
    font-weight: $headline-xs-font-weight;
    color: $secondary-900-shade;
  }

  .headline2 {
    font-size: $headline-s-font-size;
    line-height: $headline-s-line-height;
    font-family: $headline-s-font-family;
    font-weight: $headline-s-font-weight;
    color: $secondary-900-shade;
  }

  .headline1 {
    font-size: $headline-m-font-size;
    line-height: $headline-m-line-height;
    font-family: $headline-m-font-family;
    font-weight: $headline-m-font-weight;
    color: $secondary-900-shade;
  }

  .uiText4 {
    font-size: $text-xxs-regular-font-size;
    line-height: $text-xxs-regular-line-height;
    font-family: $text-xxs-regular-font-family;
    font-weight: $text-xxs-regular-font-weight;
    color: $secondary-900-shade;

    &.bold {
      font-weight: $text-xxs-bold-font-weight;
    }
  }

  .uiText3 {
    font-size: $text-xs-regular-font-size;
    line-height: $text-xs-regular-line-height;
    font-family: $text-xs-regular-font-family;
    font-weight: $text-xs-regular-font-weight;
    color: $secondary-900-shade;

    &.bold {
      font-weight: $text-xs-bold-font-weight;
    }
  }

  .uiText2 {
    font-size: $text-s-regular-font-size;
    line-height: $text-s-regular-line-height;
    font-family: $text-s-regular-font-family;
    font-weight: $text-s-regular-font-weight;
    color: $secondary-900-shade;

    &.bold {
      font-weight: $text-xxs-bold-font-weight;
    }
  }

  .uiText1 {
    font-size: $text-m-regular-font-size;
    line-height: $text-m-regular-line-height;
    font-family: $text-m-regular-font-family;
    font-weight: $text-m-regular-font-weight;
    color: $secondary-900-shade;

    &.bold {
      font-weight: $text-m-bold-font-weight;
    }
  }

  .paragraph3 {
    font-size: $paragraph-xs-font-size;
    line-height: $paragraph-xs-line-height;
    font-family: $paragraph-xs-font-family;
    font-weight: $paragraph-xs-font-weight;
    color: $secondary-900-shade;
  }

  .paragraph2 {
    font-size: $paragraph-s-font-size;
    line-height: $paragraph-s-line-height;
    font-family: $paragraph-s-font-family;
    font-weight: $paragraph-s-font-weight;
    color: $secondary-900-shade;
  }

  .paragraph1 {
    font-size: $paragraph-m-font-size;
    line-height: $paragraph-m-line-height;
    font-family: $paragraph-m-font-family;
    font-weight: $paragraph-m-font-weight;
    color: $secondary-900-shade;
  }
}

// Tablet
@mixin tablet-fonts {
  .formds-common-header-primary {
    font-family: $font-family-roboto;
    font-size: $font-size-8;
    line-height: $line-height-9;
    font-weight: $font-roboto-weight-0;
    letter-spacing: $letter-spacing-4;
    text-wrap: balance;
    &.bold {
      font-weight: $font-roboto-weight-2;
    }
  }
  .formds-common-header-secondary {
    font-family: $font-family-roboto;
    font-size: $font-size-7;
    line-height: $line-height-8;
    font-weight: $font-roboto-weight-0;
    letter-spacing: $letter-spacing-3;
    text-wrap: balance;
    &.bold {
      font-weight: $font-roboto-weight-2;
    }
  }

  .formds-common-header-tertiary {
    font-family: $font-family-roboto;
    font-size: $font-size-6;
    line-height: $line-height-7;
    font-weight: $font-roboto-weight-0;
    letter-spacing: $letter-spacing-2;
    text-wrap: balance;
    &.bold {
      font-weight: $font-roboto-weight-2;
    }
  }

  .formds-common-subtitle-primary {
    font-family: $font-family-roboto;
    font-size: $font-size-2;
    line-height: $line-height-2;
    font-weight: $font-roboto-weight-0;
    letter-spacing: $letter-spacing-1;
    text-wrap: balance;
    &.bold {
      font-weight: $font-roboto-weight-2;
    }
  }

  .formds-common-subtitle-secondary {
    font-family: $font-family-roboto;
    font-size: $font-size-1;
    line-height: $line-height-1;
    font-weight: $font-roboto-weight-0;
    letter-spacing: $letter-spacing-1;
    text-wrap: balance;
    &.bold {
      font-weight: $font-roboto-weight-2;
    }
  }

  .formds-common-subtitle-tertiary {
    font-family: $font-family-roboto;
    font-size: $font-size-1;
    line-height: $line-height-1;
    font-weight: $font-roboto-weight-0;
    letter-spacing: $letter-spacing-1;
    text-wrap: balance;
    &.bold {
      font-weight: $font-roboto-weight-0;
    }
  }

  .formds-altibox-header-primary {
    font-family: $font-family-gt-walsheim;
    font-size: $font-size-8;
    line-height: $line-height-9;
    font-weight: $font-walsheim-weight-0;
    letter-spacing: $letter-spacing-4;
    text-wrap: balance;
    &.bold {
      font-weight: $font-walsheim-weight-2;
    }
  }

  .formds-altibox-header-secondary {
    font-family: $font-family-gt-walsheim;
    font-size: $font-size-7;
    line-height: $line-height-8;
    font-weight: $font-walsheim-weight-0;
    letter-spacing: $letter-spacing-3;
    text-wrap: balance;
    &.bold {
      font-weight: $font-walsheim-weight-2;
    }
  }

  .formds-altibox-header-tertiary {
    font-family: $font-family-gt-walsheim;
    font-size: $font-size-6;
    line-height: $line-height-7;
    font-weight: $font-walsheim-weight-0;
    letter-spacing: $letter-spacing-2;
    text-wrap: balance;
    &.bold {
      font-weight: $font-walsheim-weight-2;
    }
  }

  .formds-altibox-subtitle-primary {
    font-family: $font-family-gt-walsheim;
    font-size: $font-size-5;
    line-height: $line-height-6;
    font-weight: $font-walsheim-weight-0;
    letter-spacing: $letter-spacing-2;
    text-wrap: balance;
    &.bold {
      font-weight: $font-walsheim-weight-2;
    }
  }

  .formds-altibox-subtitle-secondary {
    font-family: $font-family-gt-walsheim;
    font-size: $font-size-3;
    line-height: $line-height-4;
    font-weight: $font-walsheim-weight-0;
    letter-spacing: $letter-spacing-1;
    text-wrap: balance;
    &.bold {
      font-weight: $font-walsheim-weight-2;
    }
  }

  .formds-altibox-subtitle-tertiary {
    font-family: $font-family-gt-walsheim;
    font-size: $font-size-1;
    line-height: $line-height-3;
    font-weight: $font-walsheim-weight-0;
    letter-spacing: $letter-spacing-1;
    text-wrap: balance;
    &.bold {
      font-weight: $font-walsheim-weight-1;
    }
  }

  .formds-lead {
    font-family: $font-family-roboto;
    font-size: $font-size-2;
    line-height: $line-height-2;
    font-weight: $font-roboto-weight-0;
    letter-spacing: $letter-spacing-1;
    &.bold {
      font-weight: $font-roboto-weight-2;
    }
  }

  .formds-body {
    font-family: $font-family-roboto;
    font-size: $font-size-0;
    line-height: $line-height-0;
    font-weight: $font-roboto-weight-0;
    letter-spacing: $letter-spacing-0;
    &.bold {
      font-weight: $font-roboto-weight-2;
    }
  }

  .headline5 {
    font-size: $headline-xxs-font-size;
    line-height: $headline-xxs-line-height;
    font-family: $headline-xxs-font-family;
    font-weight: $headline-xxs-font-weight;
    color: $secondary-900-shade;
  }

  .headline4 {
    font-size: $headline-xs-font-size;
    line-height: $headline-xs-line-height;
    font-family: $headline-xs-font-family;
    font-weight: $headline-xs-font-weight;
    color: $secondary-900-shade;
  }

  .headline3 {
    font-size: $headline-s-font-size;
    line-height: $headline-s-line-height;
    font-family: $headline-s-font-family;
    font-weight: $headline-s-font-weight;
    color: $secondary-900-shade;
  }

  .headline2 {
    font-size: $headline-m-font-size;
    line-height: $headline-m-line-height;
    font-family: $headline-m-font-family;
    font-weight: $headline-m-font-weight;
    color: $secondary-900-shade;
  }

  .headline1 {
    font-size: $headline-l-font-size;
    line-height: $headline-l-line-height;
    font-family: $headline-l-font-family;
    font-weight: $headline-l-font-weight;
    color: $secondary-900-shade;
  }

  .uiText4 {
    font-size: $text-xs-regular-font-size;
    line-height: $text-xs-regular-line-height;
    font-family: $text-xs-regular-font-family;
    font-weight: $text-xs-regular-font-weight;
    color: $secondary-900-shade;
    &.bold {
      font-weight: $text-xs-bold-font-weight;
    }
  }

  .uiText3 {
    font-size: $text-s-regular-font-size;
    line-height: $text-s-regular-line-height;
    font-family: $text-s-regular-font-family;
    font-weight: $text-s-regular-font-weight;
    color: $secondary-900-shade;
    &.bold {
      font-weight: $text-s-bold-font-weight;
    }
  }

  .uiText2 {
    font-size: $text-m-regular-font-size;
    line-height: $text-m-regular-line-height;
    font-family: $text-m-regular-font-family;
    font-weight: $text-m-regular-font-weight;
    color: $secondary-900-shade;
    &.bold {
      font-weight: $text-m-bold-font-weight;
    }
  }

  .uiText1 {
    font-size: $text-l-regular-font-size;
    line-height: $text-l-regular-line-height;
    font-family: $text-l-regular-font-family;
    font-weight: $text-l-regular-font-weight;
    color: $secondary-900-shade;
    &.bold {
      font-weight: $text-l-bold-font-weight;
    }
  }

  .paragraph3 {
    font-size: $paragraph-s-font-size;
    line-height: $paragraph-s-line-height;
    font-family: $paragraph-s-font-family;
    font-weight: $paragraph-s-font-weight;
    color: $secondary-900-shade;
  }

  .paragraph2 {
    font-size: $paragraph-m-font-size;
    line-height: $paragraph-m-line-height;
    font-family: $paragraph-m-font-family;
    font-weight: $paragraph-m-font-weight;
    color: $secondary-900-shade;
  }

  .paragraph1 {
    font-size: $paragraph-l-font-size;
    line-height: $paragraph-l-line-height;
    font-family: $paragraph-l-font-family;
    font-weight: $paragraph-l-font-weight;
    color: $secondary-900-shade;
  }
}
// Desktop
@mixin desktop-fonts {
  .formds-common-header-primary {
    font-family: $font-family-roboto;
    font-size: $font-size-8;
    line-height: $line-height-9;
    font-weight: $font-roboto-weight-0;
    letter-spacing: $letter-spacing-4;
    text-wrap: balance;
    &.bold {
      font-weight: $font-roboto-weight-2;
    }
  }
  .formds-common-header-secondary {
    font-family: $font-family-roboto;
    font-size: $font-size-7;
    line-height: $line-height-8;
    font-weight: $font-roboto-weight-0;
    letter-spacing: $letter-spacing-3;
    text-wrap: balance;
    &.bold {
      font-weight: $font-roboto-weight-2;
    }
  }

  .formds-common-header-tertiary {
    font-family: $font-family-roboto;
    font-size: $font-size-6;
    line-height: $line-height-7;
    font-weight: $font-roboto-weight-0;
    letter-spacing: $letter-spacing-2;
    text-wrap: balance;
    &.bold {
      font-weight: $font-roboto-weight-2;
    }
  }

  .formds-common-subtitle-primary {
    font-family: $font-family-roboto;
    font-size: $font-size-2;
    line-height: $line-height-2;
    font-weight: $font-roboto-weight-0;
    letter-spacing: $letter-spacing-1;
    text-wrap: balance;
    &.bold {
      font-weight: $font-roboto-weight-2;
    }
  }

  .formds-common-subtitle-secondary {
    font-family: $font-family-roboto;
    font-size: $font-size-1;
    line-height: $line-height-1;
    font-weight: $font-roboto-weight-0;
    letter-spacing: $letter-spacing-1;
    text-wrap: balance;
    &.bold {
      font-weight: $font-roboto-weight-2;
    }
  }

  .formds-common-subtitle-tertiary {
    font-family: $font-family-roboto;
    font-size: $font-size-1;
    line-height: $line-height-1;
    font-weight: $font-roboto-weight-0;
    letter-spacing: $letter-spacing-1;
    text-wrap: balance;
    &.bold {
      font-weight: $font-roboto-weight-0;
    }
  }

  .formds-altibox-header-primary {
    font-family: $font-family-gt-walsheim;
    font-size: $font-size-8;
    line-height: $line-height-9;
    font-weight: $font-walsheim-weight-0;
    letter-spacing: $letter-spacing-4;
    text-wrap: balance;
    &.bold {
      font-weight: $font-walsheim-weight-2;
    }
  }

  .formds-altibox-header-secondary {
    font-family: $font-family-gt-walsheim;
    font-size: $font-size-7;
    line-height: $line-height-8;
    font-weight: $font-walsheim-weight-0;
    letter-spacing: $letter-spacing-3;
    text-wrap: balance;
    &.bold {
      font-weight: $font-walsheim-weight-2;
    }
  }

  .formds-altibox-header-tertiary {
    font-family: $font-family-gt-walsheim;
    font-size: $font-size-6;
    line-height: $line-height-7;
    font-weight: $font-walsheim-weight-0;
    letter-spacing: $letter-spacing-2;
    text-wrap: balance;
    &.bold {
      font-weight: $font-walsheim-weight-2;
    }
  }

  .formds-altibox-subtitle-primary {
    font-family: $font-family-gt-walsheim;
    font-size: $font-size-5;
    line-height: $line-height-6;
    font-weight: $font-walsheim-weight-0;
    letter-spacing: $letter-spacing-2;
    text-wrap: balance;
    &.bold {
      font-weight: $font-walsheim-weight-2;
    }
  }

  .formds-altibox-subtitle-secondary {
    font-family: $font-family-gt-walsheim;
    font-size: $font-size-3;
    line-height: $line-height-4;
    font-weight: $font-walsheim-weight-0;
    letter-spacing: $letter-spacing-1;
    text-wrap: balance;
    &.bold {
      font-weight: $font-walsheim-weight-2;
    }
  }

  .formds-altibox-subtitle-tertiary {
    font-family: $font-family-gt-walsheim;
    font-size: $font-size-1;
    line-height: $line-height-3;
    font-weight: $font-walsheim-weight-0;
    letter-spacing: $letter-spacing-1;
    text-wrap: balance;
    &.bold {
      font-weight: $font-walsheim-weight-1;
    }
  }

  .formds-lead {
    font-family: $font-family-roboto;
    font-size: $font-size-2;
    line-height: $line-height-2;
    font-weight: $font-roboto-weight-0;
    letter-spacing: $letter-spacing-1;
    &.bold {
      font-weight: $font-roboto-weight-2;
    }
  }

  .formds-body {
    font-family: $font-family-roboto;
    font-size: $font-size-0;
    line-height: $line-height-0;
    font-weight: $font-roboto-weight-0;
    letter-spacing: $letter-spacing-0;
    &.bold {
      font-weight: $font-roboto-weight-2;
    }
  }
  .headline5 {
    font-size: $headline-xs-font-size;
    line-height: $headline-xs-line-height;
    font-family: $headline-xs-font-family;
    font-weight: $headline-xs-font-weight;
    color: $secondary-900-shade;
  }

  .headline4 {
    font-size: $headline-s-font-size;
    line-height: $headline-s-line-height;
    font-family: $headline-s-font-family;
    font-weight: $headline-s-font-weight;
    color: $secondary-900-shade;
  }

  .headline3 {
    font-size: $headline-m-font-size;
    line-height: $headline-m-line-height;
    font-family: $headline-m-font-family;
    font-weight: $headline-m-font-weight;
    color: $secondary-900-shade;
  }

  .headline2 {
    font-size: $headline-l-font-size;
    line-height: $headline-l-line-height;
    font-family: $headline-l-font-family;
    font-weight: $headline-l-font-weight;
    color: $secondary-900-shade;
  }

  .headline1 {
    font-size: $headline-xl-font-size;
    line-height: $headline-xl-line-height;
    font-family: $headline-xl-font-family;
    font-weight: $headline-xl-font-weight;
    color: $secondary-900-shade;
  }

  .uiText4 {
    font-size: $text-s-regular-font-size;
    line-height: $text-s-regular-line-height;
    font-family: $text-s-regular-font-family;
    font-weight: $text-s-regular-font-weight;
    color: $secondary-900-shade;
    &.bold {
      font-weight: $text-s-bold-font-weight;
    }
  }

  .uiText3 {
    font-size: $text-m-regular-font-size;
    line-height: $text-m-regular-line-height;
    font-family: $text-m-regular-font-family;
    font-weight: $text-m-regular-font-weight;
    color: $secondary-900-shade;
    &.bold {
      font-weight: $text-m-bold-font-weight;
    }
  }

  .uiText2 {
    font-size: $text-l-regular-font-size;
    line-height: $text-l-regular-line-height;
    font-family: $text-l-regular-font-family;
    font-weight: $text-l-regular-font-weight;
    color: $secondary-900-shade;
    &.bold {
      font-weight: $text-l-bold-font-weight;
    }
  }

  .uiText1 {
    font-size: $text-xl-regular-font-size;
    line-height: $text-xl-regular-line-height;
    font-family: $text-xl-regular-font-family;
    font-weight: $text-xl-regular-font-weight;
    color: $secondary-900-shade;
    &.bold {
      font-weight: $text-xl-bold-font-weight;
    }
  }

  .paragraph3 {
    font-size: $paragraph-m-font-size;
    line-height: $paragraph-m-line-height;
    font-family: $paragraph-m-font-family;
    font-weight: $paragraph-m-font-weight;
    color: $secondary-900-shade;
  }

  .paragraph2 {
    font-size: $paragraph-l-font-size;
    line-height: $paragraph-l-line-height;
    font-family: $paragraph-l-font-family;
    font-weight: $paragraph-l-font-weight;
    color: $secondary-900-shade;
  }

  .paragraph1 {
    font-size: $paragraph-xl-font-size;
    line-height: $paragraph-xl-line-height;
    font-family: $paragraph-xl-font-family;
    font-weight: $paragraph-xl-font-weight;
    color: $secondary-900-shade;
  }
}
