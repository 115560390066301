@import 'variables';

.stepsHeader {
  margin: 0 ($spacing * 2);

  .steps {
    display: flex;
    list-style: none;
    margin-top: 0;
    margin-bottom: ($spacing * 7);
    padding: 0;

    .step {
      .container {
        align-items: center;
        background-color: transparent;
        border: 0;
        display: flex;
        margin-left: 1.75rem;
        padding: 0;

        .number {
          align-items: center;
          background-color: $dark-grey;
          border-radius: 100%;
          color: $white;
          display: flex;
          height: 1.5rem;
          width: 1.5rem;
          justify-content: center;
          font-size: 0.875rem;

          &.active {
            background-color: $red;
          }
        }

        .heading {
          display: none;
          margin-left: $spacing;

          &.show {
            display: block;
          }
          @media (min-width: $screen-md) {
            display: block;
          }
        }
      }

      &:first-child {
        .button {
          margin-left: 0;
        }
      }
    }
  }

  @media (min-width: $screen-md) {
    margin: 0;
  }
}
