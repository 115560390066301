@import 'variables';

.mainMenu {
  position: fixed;
  z-index: 10;
  bottom: 0;
  width: 100%;
  background-color: $white;
  border-top: 1px solid $medium-grey;

  ul {
    display: flex;
    justify-content: space-evenly;
    margin: 0;
    padding: 0;

    li {
      list-style: none;

      a {
        display: block;
        text-align: center;
        text-decoration: none;
        padding: $spacing;
        min-width: 77px;

        .stroke {
          stroke: $black;
        }

        .fill {
          fill: $black;
        }

        div {
          font-family: Roboto, sans-serif;
          font-size: $uiText-sm-font-size;
          line-height: $uiText-sm-line-height;
          font-weight: 700;
        }

        &:hover {
          background-color: $light-grey;
        }

        &:active,
        &.active {
          .stroke {
            stroke: $red;
          }

          .fill {
            fill: $red;
          }

          div {
            span {
              border-bottom: solid 2px $red;
            }
          }
        }
      }
    }
  }

  @media (min-width: $screen-md) {
    position: static;
    margin-bottom: ($spacing * 3);

    ul {
      margin: (3 * $spacing) 0 0;
      justify-content: center;

      li {
        margin: 0 (3 * $spacing);

        a {
          min-width: 98px;

          div {
            font-size: $uiText-md-font-size;
            line-height: $uiText-md-line-height;
          }
        }
      }
    }
  }
}
