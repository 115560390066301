@import 'variables';
@import 'src/app/component-library-wave/designtokens';

.heading {
  margin-bottom: $spacing * 2;
}

.createNewEmailInformation {
  margin-bottom: $spacing * 2;
}

.createNewAccountButton {
  margin-bottom: $spacing * 8;
}

.emailAccountList {
  padding: 0;
  margin-bottom: $spacing * 8;

  .emailAccount {
    list-style: none;
    margin-bottom: $spacing * 3;

    .emailAccountContainer {
      border: 1px solid $secondary-200-tint;
      border-radius: $border-radius;
      padding: $mobile-edge;
      @media (min-width: $screen-md) {
        padding: $spacing * 3;
      }

      .emailAddressRow {
        display: flex;
        align-items: center;
        margin-bottom: $spacing * 2;

        .mailIcon {
          min-width: 24px;
          margin-right: $spacing * 2;
        }

        .emailAddress {
          word-break: break-word;
        }
      }

      .buttonContainer {
        display: flex;
        flex-wrap: wrap;

        .accountCtaButton {
          margin: 0 12px $spacing 0;

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
