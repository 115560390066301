@import 'variables';

.container {
  display: flex;
  align-items: center;
  place-content: center flex-start;
  flex-direction: column;
  margin: ($spacing * 9) auto ($spacing * 20);
  max-width: $grid-narrow;
  padding: 0 ($spacing * 2);

  .imageContainer {
    width: 105px;
    margin-bottom: $spacing * 5;
  }
}

.heading {
  margin-bottom: $spacing * 3;
  text-align: center;

  .noWrap {
    white-space: nowrap;
  }
}

.message {
  text-align: center;
}

@media (min-width: $screen-md) {
  .container {
    margin: ($spacing * 19) auto ($spacing * 30);

    .imageContainer {
      width: 144px;
    }
  }

  .heading {
    margin-bottom: $spacing * 4;
  }
}
