@import '../../../variables';

.breadcrumbs {
  display: none;
  @media (min-width: $screen-md) {
    display: flex;
    margin-bottom: ($spacing * 7);
  }
  background-color: $secondary050;
  font-size: $uiText-md-font-size;
  font-family: Roboto, sans-serif;
  font-weight: 700;

  ol {
    display: flex;
    padding: 0;
    margin: 0 0 0 2px;

    li {
      display: flex;
      list-style: none;
      padding: 21px 0;

      > div,
      a,
      &::after {
        padding: 4px 8px;
      }

      a {
        text-decoration: none;

        div {
          border-bottom: 2px solid $black;
        }

        &:hover {
          background-color: $medium-grey;
        }
      }

      &::after {
        /* fallback for browser not supporting the pseudo alt-text on next line */
        /* stylelint-disable-next-line */
        content: '/' / '';
      }

      &:last-of-type {
        a div {
          border-bottom: 0;
        }

        &::after {
          content: '';
        }
      }
    }
  }
}
