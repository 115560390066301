@import 'src/variables';
@import 'src/app/component-library-wave/designtokens';

.container {
  margin: 0 $mobile-edge;
}

.toast {
  margin-bottom: $spacing * 8;
}

.card {
  margin-bottom: $spacing * 7;

  &:last-of-type {
    margin-bottom: $spacing * 9;
  }
}

.cardTitle {
  text-align: center;
  margin-bottom: $spacing * 2;
}

.cardSection {
  padding-bottom: $spacing * 2;
}

.label {
  padding-bottom: $spacing;

  @media (min-width: $screen-sm) {
    padding-bottom: $spacing * 2;
  }
}

.partnerSection {
  display: flex;
  align-items: center;

  .logo {
    margin-right: $spacing * 2;
    width: 72px;
    max-height: 48px;

    @media (min-width: $screen-sm) {
      margin-right: $spacing * 4;
    }
  }
}

.editButton {
  max-width: fit-content;
  margin: $spacing * 5 0 0;

  @media (min-width: $screen-sm) {
    margin: $spacing * 5 0 0;
  }
}

.name {
  padding-bottom: $spacing;
}

.emailAddress {
  padding-bottom: $spacing;
}
