@import '/src/app/component-library-wave/designtokens.scss';
@import './button.mixin';

.button {
  @include xs-button-base;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $neutrals-white;
  padding: 11px ($spacing * 2);
  border: none;
  outline: none;
  margin: 6px;
  border-radius: 3px;
  .text {
    margin: 0 10px;
  }
  .icon {
    margin: 0 $spacing;
    @include icon-xs;
  }
  &.small {
    @include xxs-button-base;
    .icon {
      @include icon-xxs;
    }
  }
  &.large {
    @include s-button-base;
    .icon {
      @include icon-s;
    }
  }
  &:focus-visible {
    outline-offset: 3px;
    outline: solid 3px $guidance-500-shade-core;
  }
  &:disabled {
    background-color: $secondary-100-tint;
    color: $secondary-400-tint;
  }

  &:enabled:hover {
    cursor: pointer;
  }
}

.primaryA {
  background: $critical-500-shade-core;

  &:enabled:hover {
    background: $primary-400-tint;
  }
  &:enabled:active {
    background: $primary-300-tint;
  }
}

.primaryB {
  background: $secondary-900-shade;

  &:enabled:hover {
    background: $secondary-700-shade;
  }
  &:enabled:active {
    background: $secondary-800-shade;
  }
}

.secondary {
  background: transparent;
  color: $secondary-900-shade;
  border: 2px solid $secondary-900-shade;

  &:enabled:hover {
    color: $secondary-800-shade;
    border: 2px solid $secondary-800-shade;
  }

  &:enabled:active {
    color: $secondary-700-shade;
    border: 2px solid $secondary-700-shade;
  }

  &:disabled {
    background: transparent;
    color: $secondary-400-tint;
    border: 2px solid $secondary-400-tint;
  }
}

.tertiary {
  .text {
    @include underline;
  }
  background: transparent;
  color: $secondary-900-shade;

  &:enabled:hover {
    color: $secondary-800-shade;
  }
  &:enabled:active {
    color: $secondary-700-shade;
  }
  &:disabled {
    background: transparent;
    color: $secondary-400-tint;
  }
}

// Tablet
@media (min-width: $screen-s) {
  .button {
    @include s-button-base;
    .icon {
      @include icon-s;
    }
    &.small {
      @include xs-button-base;
      .icon {
        @include icon-xs;
      }
    }
    &.large {
      .icon {
        @include icon-m;
      }
      @include m-button-base;
      padding: 12px ($spacing * 2);
      border-radius: 4px;
    }
  }
}

// Desktop
@media (min-width: $screen-m) {
  .button {
    @include m-button-base;
    padding: 12px ($spacing * 2);
    border-radius: 4px;
    .icon {
      @include icon-m;
    }
    &.small {
      @include s-button-base;
      .icon {
        @include icon-s;
      }
    }
    &.large {
      @include l-button-base;
      .icon {
        @include icon-l;
      }
      border-radius: 6px;
      padding: 14px ($spacing * 3) 15px;
    }
  }
}

.darkTheme {
  &.button {
    &:focus-visible {
      outline-offset: 3px;
      outline: solid 3px $guidance-300-tint;
    }
  }
  &.primaryB {
    color: $transparency-black-900;
    background: $neutrals-white;

    &:disabled {
      color: $neutrals-800-shade;
      background: $transparency-white-400;
    }
    &:enabled:hover {
      background: $neutrals-200-tint;
    }
    &:enabled:active {
      background: $neutrals-300-tint;
    }
  }

  &.secondary {
    color: $neutrals-white;
    border: 2px solid $neutrals-white;
    background-color: $secondary-900-shade;

    &:disabled {
      color: $transparency-white-400;
      border: 2px solid $transparency-white-400;
    }

    &:enabled:hover {
      color: $neutrals-200-tint;
      border: 2px solid $neutrals-200-tint;
    }

    &:enabled:active {
      color: $neutrals-300-tint;
      border: 2px solid $neutrals-300-tint;
    }
  }

  &.tertiary {
    color: $neutrals-white;
    background-color: $secondary-900-shade;
    .text {
      border-bottom: 2px solid $neutrals-white;
    }
    &:disabled {
      color: $transparency-white-400;
    }
    &:enabled:hover {
      color: $neutrals-200-tint;
    }
    &:enabled:active {
      color: $neutrals-300-tint;
    }
  }
}
