@import 'variables';

$screen-sm: '480px'; /* custom footer breakpoint */

.footer {
  margin-top: $spacing * 3;
  background-color: $secondary-900;
  color: $white;
  text-align: center;
  padding-bottom: 153px; /* 144px from design + static footer height 65px - ContainerFixed 56px */
  @media (min-width: $screen-md) {
    padding-bottom: ($spacing * 9);
    margin-top: 132px;
  }

  .loggedInUser {
    background-color: $secondary-800;
    margin: 0 0 ($spacing * 4);
    padding: ($spacing * 2) 0 ($spacing * 3);

    .userAddressList {
      display: block;
      list-style: none;
      margin: 0;
      padding: 0;

      .userAddressListItem {
        margin-top: $spacing;
        color: $white;
      }

      @media (min-width: $screen-md) {
        display: flex;
        justify-content: center;

        .userAddressListItem {
          &::after {
            /* fallback for browser not supporting the pseudo alt-text on next line */
            /* stylelint-disable-next-line */
            content: '/' / '';
            padding: 0 $spacing * 2;
          }

          &:last-of-type {
            &::after {
              content: '';
              padding: 0;
            }
          }
        }
      }
    }
    @media (min-width: $screen-sm) {
      margin-bottom: $spacing * 6;
    }
  }

  .desktopRowReverse {
    @media (min-width: $screen-sm) {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      margin: 0 ($spacing * 6) 0 ($spacing * 7);
    }
    @media (min-width: $grid-wide) {
      margin: 0 auto;
    }
  }

  .footerNavigation {
    margin: 0 0 ($spacing * 4);

    .footerNavigationList {
      list-style: none;
      margin: 0;
      padding: 0;

      .footerNavigationListItem {
        &:last-of-type {
          @media (min-width: $screen-md) {
            text-align: left;
          }
        }

        .footerNavigationListItemLink {
          color: $white;
          display: block;
          padding: $spacing;

          .linkText {
            color: $white;
          }
          @media (min-width: $screen-lg) {
            display: inline;
          }
        }

        @media (min-width: $screen-lg) {
          &::after {
            color: $white; /* fallback for browser not supporting the pseudo alt-text on next line */
            /* stylelint-disable-next-line */
            content: '/' / '';
            padding: 0 $spacing;
          }

          &:last-of-type {
            &::after {
              content: '';
              padding: 0;
            }
          }
        }
      }
      @media (min-width: $screen-lg) {
        display: flex;
      }
    }
    @media (min-width: $screen-sm) {
      text-align: right;
      margin: 0;
    }
  }

  .partnerCoBranding {
    margin: 0 auto;
    width: 260px;

    @media (min-width: $screen-sm) {
      margin: 0;
    }

    .logo {
      max-width: 100%;
    }
  }

  .logoAltibox {
    width: 170px;
  }
}
