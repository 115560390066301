@import 'variables';
@import 'src/app/component-library-wave/designtokens';

.container {
  row-gap: $spacing * 7;
  margin-bottom: $spacing * 9;
  text-align: center;
}

.headline {
  margin-bottom: $spacing * 2;
}

.cta {
  font-size: $text-s-bold-font-size;
  color: $neutrals-white;
  background-color: $primary-500-shade-core;
  text-decoration: none;
  padding: $spacing * 1.5 $spacing * 2;
  border-radius: 3px;
  font-weight: $text-s-bold-font-weight;
}

.information {
  background-color: $secondary-50;
  text-align: center;
  padding: ($spacing * 4) ($spacing * 6);
  margin: 0 $spacing (11 * $spacing);
}

.informationHeadline {
  margin-bottom: 3 * $spacing;
}

.informationParagraph {
  margin-bottom: 7 * $spacing;
}

.arrow {
  stroke: $white;
  margin-left: $spacing * 1.5;
  vertical-align: top;
}
