@import 'src/variables';
@import 'src/app/component-library-wave/designtokens';

.networkSettingsContainer {
  margin: $mobile-edge;
  @media (min-width: $screen-md) {
    margin: $spacing * 4;
  }
}

.header {
  margin-bottom: $spacing * 4;
  overflow-wrap: anywhere;
}

.linkAdvancedSettings {
  @media (min-width: $screen-md) {
    display: none;
  }
}
