@import 'variables';
@import 'src/app/component-library-wave/designtokens';

.ipAddress {
  span:not(:first-of-type)::before {
    margin-right: $spacing * 0.5;
    margin-left: $spacing * 0.5;

    @media (min-width: $screen-sm) {
      margin-right: $spacing;
      margin-left: $spacing;
    }
    content: '.';
  }

  span:last-of-type {
    margin-right: 0;
  }
}

.buttonDisabled {
  color: $secondary-400-tint;
  background-color: $secondary-100-tint;

  &:hover {
    /* stylelint-disable-next-line declaration-no-important */
    background-color: $secondary-100-tint !important;
  }
}

.toast {
  width: 100%;
  margin-bottom: $spacing * 2;
  @media (min-width: $screen-md) {
    margin-bottom: $spacing * 3;
  }
}
