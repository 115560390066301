@import 'src/variables';

@mixin arrow {
  border: solid $neutral-300;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 0;
  width: 17px;
}

@mixin right {
  transform: rotate(-45deg);
}

@mixin left {
  transform: rotate(135deg);
}

:root {
  --swiper-navigation-size: #{$spacing * 3};
  @media (min-width: $screen-md) {
    --swiper-navigation-size: #{$spacing * 4};
  }
}

.swiper {
  div {
    --swiper-navigation-size: #{$spacing * 2};
  }
}

.fullScreen [class^='swiper-button-next'],
.swiper [class^='swiper-button-next'] {
  &::after {
    @include arrow;
    @include right;
  }
}

.fullScreen [class^='swiper-button-prev'],
.swiper [class^='swiper-button-prev'] {
  &::after {
    @include arrow;
    @include left;
  }
}

.swiper [class^='swiper-button-next'],
.swiper [class^='swiper-button-prev'] {
  top: 40%;
  color: $black;
  width: 25px;
  height: 25px;
  @media (min-width: $screen-md) {
    width: 20%;
  }
}

.fullScreen {
  background: $white;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
}

.fullScreen [class^='swiper-button-next'],
.fullScreen [class^='swiper-button-prev'] {
  color: $dark-grey;
  width: 15%;
  z-index: 11;
  height: 33%;
  top: 66%;
  align-items: flex-end;
  @media (min-width: $screen-md) {
    height: $spacing * 8;
    top: 50%;
    align-items: center;
  }

  &::after {
    width: 34px;
    padding: 0;
  }
}

.fullScreen [class^='swiper-button-next'] {
  margin-right: $spacing;
}

.swiperImage {
  margin-bottom: $spacing * 4;
  display: block;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  cursor: zoom-in;
  @media (min-width: $screen-md) {
    width: 60%;
  }
}

.imgFullScreen {
  display: block;
  cursor: zoom-out;
  width: 90%;
  @media (min-width: $screen-md) {
    width: 100%;
    height: 90vh;
  }
}

.iconClose,
.iconMaximize {
  cursor: pointer;
  position: absolute;
  right: 0;
  padding: $spacing;
  z-index: 2;
  @media (min-width: $screen-md) {
    right: $spacing * 4;
  }
}

.iconClose {
  top: $spacing * 4;
  right: $spacing * 4;
  margin: 0 $spacing;
  background-color: $white;
}

.iconMaximize {
  top: $spacing * 2;
  right: $spacing * 2;
  cursor: pointer;
  max-height: 40px;
}

.iconZoomIn,
.iconZoomOut {
  z-index: 2;
  cursor: pointer;
  position: absolute;
  left: $spacing * 2;
  background-color: $white;
  padding: $spacing;
  @media (min-width: $screen-md) {
    left: $spacing * 4;
  }
}

.iconZoomIn {
  top: $spacing * 4;
}

.iconZoomOut {
  top: $spacing * 12;
}

.controlsContainer {
  z-index: 100;
}

.imageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: inherit;

  & [class^='swiper'] {
    margin: $spacing * 3;
    @media (min-width: $screen-md) {
      max-width: 80%;
    }
  }
}

.imageZoomedInContainer {
  overflow: hidden;
  margin: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  @media (orientation: landscape) {
    justify-content: space-between;
    overflow: visible;
  }
}

.imageText {
  padding-top: $spacing * 3;
  position: relative;
  bottom: 0;
  width: 90vw;
  margin-left: $mobile-edge;
  margin-right: $mobile-edge;
  background-color: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: $spacing * 8;
  @media (orientation: landscape) {
    bottom: $spacing * 10;
  }
}

.imageTitle {
  margin-bottom: $spacing;
}
