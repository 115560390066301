@import 'src/variables';
@import '/src/app/component-library-wave/designtokens.scss';
@import './grid.mixins';

$lg-gutter: 40px;
$lg-margin: 56px;
$lg-columns: 12;

$md-gutter: 24px;
$md-margin: 32px;
$md-columns: 6;

$sm-gutter: 16px;
$sm-margin: 16px;
$sm-columns: 2;

.outerContainer {
  width: 100%;
}
.container {
  display: flex;
  flex-flow: row wrap;
  box-sizing: border-box;
  margin: 0 auto;
}

.narrowWidth {
  max-width: 540px;
}

.wideWidth {
  max-width: 1140px;
}

// Mobile
@media (max-width: ($screen-s - 1)) {
  .container {
    margin: 0 auto;
    padding-left: $sm-margin - calc($sm-gutter / 2);
    padding-right: $sm-margin - calc($sm-gutter / 2);
    row-gap: 16px;
  }
  @include create-column-classes($sm-columns, $sm-gutter, 'sm');
}

// Tablet
@media (min-width: $screen-s) and (max-width: ($screen-m - 1)) {
  .container {
    margin: 0 auto;
    padding-left: $md-margin - calc($md-gutter / 2);
    padding-right: $md-margin - calc($md-gutter / 2);
    row-gap: 24px;
  }
  @include create-column-classes($md-columns, $md-gutter, 'md');
}

// Desktop
@media (min-width: $screen-m) {
  .container {
    margin: 0 auto;
    padding-left: $lg-margin - calc($lg-gutter / 2);
    padding-right: $lg-margin - calc($lg-gutter / 2);
    row-gap: 40px;
  }
  @include create-column-classes($lg-columns, $lg-gutter, 'lg');
}
