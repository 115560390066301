@import 'variables';

.inactiveWarningBanner {
  margin-bottom: $spacing * 2;

  .emailPageLink {
    color: $link;

    &:visited {
      color: $link-visited;
    }
  }
}
