@import 'src/variables';
@import 'src/app/component-library-wave/designtokens';

$country-code-input-width-m: 3.9375rem;
$country-code-input-width-l: 4.125rem;

.labelAndDescription {
  display: flex;

  .label {
    display: inline-block;
    margin-bottom: $spacing;

    &.error {
      color: $attention-700-shade;
    }
  }

  .description {
    margin-left: $spacing * 2;
  }
}

.divider {
  align-self: center;
  border-right: 1px solid $text-xxs-bold-color;
  height: 23px;
}

.codeAndPhone {
  display: flex;
  padding: 1px;
  border: 1px solid $neutrals-300-tint;
  border-radius: 2px;
  box-shadow: $box-shadow;

  &.error {
    padding: 0;
    margin-bottom: 6px;
    border: 2px solid $attention-500-tint-core;
  }

  .input {
    &.codeInput {
      width: $country-code-input-width-m;
    }

    &.phoneNumberInput {
      width: calc(100% - #{$country-code-input-width-m});
    }

    div {
      margin-bottom: 0;

      input {
        border: none;
        box-shadow: none;
        background: transparent;

        &:focus {
          box-shadow:
            -3px -3px 0 0 $guidance-500-shade-core,
            3px -3px 0 0 $guidance-500-shade-core,
            -3px 3px 0 0 $guidance-500-shade-core,
            3px 3px 0 0 $guidance-500-shade-core;
        }
      }
    }
  }

  &.maxBreakpointDesktop {
    @media (min-width: $screen-m) {
      .input {
        &.codeInput {
          width: $country-code-input-width-l;
        }

        &.phoneNumberInput {
          width: calc(100% - #{$country-code-input-width-l});
        }
      }

      .divider {
        height: 31px;
      }
    }
  }
}
