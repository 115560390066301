@import 'variables';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid $secondary200;
  border-radius: $border-radius;
  padding: ($spacing * 2);
  margin: 0 0 ($spacing * 6);

  .containerLeft {
    display: flex;
  }
}

.addressWrapper {
  margin-right: $spacing * 2;
}

.house {
  height: #{calc(32 / 16)}rem;
  width: #{calc(32 / 16)}rem;
  margin-right: $spacing * 2;
}

.title {
  margin-bottom: $spacing;
}

.streetaddress {
  margin-bottom: calc($spacing / 2);
}

// Large screens
@media (min-width: $screen-md) {
  .house {
    height: #{calc(56 / 16)}rem;
    width: #{calc(56 / 16)}rem;
    margin-right: $spacing * 2;
  }

  .container {
    padding: ($spacing * 2) ($spacing * 3);
    margin: 0 0 ($spacing * 9);
  }
}
