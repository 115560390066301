@import 'variables';

.navigationList {
  margin-bottom: $spacing * 7;
}

.alertContainer {
  margin-bottom: $spacing * 2;
}

.navigationListHeader {
  text-align: center;
  margin-bottom: ($spacing * 2);
}
