@import '../../../variables';

.navigation {
  section {
    text-align: left;

    .sectionHeader {
      margin-bottom: ($spacing * 2);
      text-align: center;
    }
  }

  .sectionList {
    margin: 0;
    border: solid 1px $medium-grey;
    border-radius: $border-radius;
    padding: $spacing * 2;

    li {
      border-bottom: 1px solid $medium-grey;
      display: flex;
      flex-direction: column;

      &::before {
        content: '';
      }

      &:last-child {
        border-bottom: 0;
      }

      &:hover {
        background-color: $light-grey;
      }

      .navLink {
        padding: $spacing * 2;
        text-decoration: none;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .linkDescription {
          margin-top: 4px;
        }

        .navLinkCol2 {
          display: flex;
          align-items: center;
          margin-left: $spacing * 2;
        }
      }
    }
  }
}

@media (min-width: $screen-md) {
  .navigation {
    section {
      .sectionList {
        padding: $spacing * 3;

        .navLink {
          padding: $spacing * 3;
        }
      }
    }
  }
}
