@import 'variables';

.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid $secondary200;
  border-radius: $border-radius;
  padding: ($spacing * 2);
  width: 100%;
}

.button {
  margin-left: auto;
  order: 3;
}

.addressWrapper {
  margin-right: $spacing * 2;
}

.house {
  height: #{calc(32 / 16)}rem;
  width: #{calc(32 / 16)}rem;
  margin-right: $spacing * 2;
}

.title {
  margin-bottom: $spacing;
}

.streetaddress {
  margin-bottom: calc($spacing / 2);
}

// Large screens
@media (min-width: $screen-md) {
  .house {
    height: #{calc(56 / 16)}rem;
    width: #{calc(56 / 16)}rem;
    margin-right: $spacing * 2;
  }

  .container {
    padding: ($spacing * 2) ($spacing * 3);
    margin-bottom: $spacing * 9;
  }
}
