@import 'src/variables';
@import 'src/app/component-library-wave/designtokens';

.rule {
  border: $border;
  border-radius: $border-radius;
  margin: 0;
  padding: $spacing * 2;
  @media (min-width: $screen-md) {
    padding: $spacing * 3;
  }
}

.name {
  padding: $spacing * 2 0 $spacing * 2 $spacing * 2;
  @media (min-width: $screen-sm) {
    padding: $spacing 0 $spacing * 2 $spacing * 2;
    border-bottom: $border;
  }
}

.type {
  padding: 0 0 $spacing * 6 $spacing * 2;
  @media (min-width: $screen-sm) {
    padding: $spacing * 3 0 $spacing * 2 $spacing * 2;
  }
}

.show {
  font-size: $headline6-md-font-size;
  font-weight: bold;
  color: $link;
  padding: 0 0 $spacing * 2 $spacing * 2;
  background: none;
  border: none;
  cursor: pointer;
  @media (min-width: $screen-sm) {
    padding: 0 0 $spacing $spacing * 2;
  }
}

.arrow {
  stroke: $link;
  position: relative;
  top: 6px;
  left: $spacing * 2;
}

.background {
  background-color: $neutrals-050-tint;
  border: solid 1px $neutrals-050-tint;
  border-radius: $border-radius;
  margin: $spacing;
  padding: $spacing * 3;

  @media (min-width: $screen-sm) {
    margin-top: $spacing;
    padding: $spacing * 5;
  }
}

.ipaddress {
  padding-bottom: ($spacing * 6);
}

.portHeading {
  padding-bottom: $spacing;
}

.externalPortNumber {
  padding-bottom: ($spacing * 4);
}

.internalPortNumber {
  padding-bottom: ($spacing * 6);
}

.button {
  display: inline;
  margin-right: $spacing * 2;

  &:first-of-type {
    background-color: $white;
  }
}
