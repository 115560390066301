@import 'src/variables';
@import 'src/app/component-library-wave/designtokens';

.metaContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 2 1 auto;
}

.linkContainer {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-start;
  text-decoration: none;

  @media (min-width: $screen-md) {
    align-items: center;
    flex-direction: row;
  }
}

.articleLinkContainer {
  padding: $spacing 0;
}

.link {
  font-size: 16px;
  white-space: nowrap;
}

.articleLink {
  border-bottom: solid;
}

.iconButton {
  margin-left: 0;
  padding-right: 0;
  padding-left: 0;
  @media (min-width: $screen-md) {
    margin-right: $spacing * 3;
  }

  > span {
    display: flex;
    align-items: center;
  }
}

.buttonLink,
.articleLink {
  display: flex;
  text-decoration: none;
}

.icon {
  width: 28px;
  height: 28px;
  margin-left: $spacing;
  margin-bottom: -$spacing;

  > svg {
    width: 24px;
    height: 24px;
  }
}

.deviceLi {
  &::before {
    content: '';
  }

  &:not(:last-of-type) {
    border-bottom: $border;
  }
}

.deviceName {
  word-break: break-word;
  text-align: left;
}

.desktopTwoColumns {
  margin-top: $spacing * 4;
  margin-bottom: $spacing * 2;
  @media (min-width: $navigation-breakpoint-medium) {
    margin-top: $spacing * 4;
    display: flex;
    flex-direction: row-reverse;
    column-gap: $spacing * 3;
    justify-content: space-between;
  }
}

.imageContainer {
  @media (min-width: $navigation-breakpoint-medium) {
    border: 0;
    padding: 0;
    margin-bottom: 0;
    max-width: 80%;
  }
}

.imageColumn {
  width: auto;
  align-items: center;
  @media (min-width: $navigation-breakpoint-medium) {
    width: 45%;
    display: flex;
    justify-content: center;
  }
}

.hgwModelType {
  margin-top: $spacing * 4;
  margin-bottom: $spacing * 2;
  font-size: $paragraph-md-font-size;
}

.hgwInfo {
  font-size: $spacing * 2;
  margin-bottom: $spacing * 2;
}

.imageNameContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: $mobile-edge;
  background: transparent;
  border: 0;

  &:hover {
    cursor: pointer;
    background-color: $light-grey;
  }

  .columnLeft {
    display: flex;
    align-items: center;
    gap: $spacing * 2;

    .deviceImage {
      margin-right: $spacing * 3;
      max-width: 40px;
      @media (min-width: $screen-md) {
        max-width: 56px;
      }
    }
  }
}

.collapsibleContent {
  margin: $spacing * 2;
  @media (min-width: $screen-md) {
    margin: $spacing * 4 $spacing * 2;
  }
}

.macAddressHeading {
  margin-bottom: $spacing;
}

.macAddress {
  margin-bottom: $spacing * 2;
}

.accessPointNameInput {
  margin-bottom: $spacing * 3;
  @media (min-width: $screen-md) {
    max-width: 633px;
  }
}

.buttonDisabled {
  color: $secondary-400-tint;
  background-color: $secondary-100-tint;

  &:hover {
    /* stylelint-disable-next-line declaration-no-important */
    background-color: $secondary-100-tint !important;
  }
}

.toastMessage {
  margin-bottom: $spacing * 3;
}
