@import 'variables';

html {
  scrollbar-gutter: stable;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

::-ms-reveal {
  display: none;
}

body {
  font-family: Roboto, sans-serif;
}

a,
body {
  color: $black;
}

.link {
  color: $guidance-500-core;
  font-weight: bold;
  text-decoration: none;

  &:visited {
    color: $support-3-600;
  }

  &:hover {
    text-decoration: underline;
  }
}

/* stylelint-disable-next-line */
#CookiebotWidget:not(.CookiebotWidget-inactive) {
  bottom: 100px;
  @media (min-width: $screen-md) {
    bottom: 10px;
  }
  @media (max-width: $screen-cookiebot) {
    /* stylelint-disable-next-line declaration-no-important */
    bottom: 100px !important;
  }
}
