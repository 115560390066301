@import 'variables';
@import 'src/app/component-library-wave/designtokens';

.container {
  &:hover {
    background-color: $guidance-050-tint;
  }
}

.checkbox {
  position: relative;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  flex-shrink: 0;
  background-color: $white;
  border: 1px solid $secondary-400-tint;
  border-radius: calc($border-radius / 2);
  box-shadow: $box-shadow;

  &::after {
    content: '';
    position: absolute;
    display: none;
  }
}

.labelContainer {
  padding: $spacing 0;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  position: relative;
  cursor: pointer;

  .checkbox::after {
    left: 7px;
    top: 1px;
    width: 9px;
    height: 17px;
    border: solid $white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }

  .checkboxInput {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkbox::after {
      display: block;
    }

    &:checked ~ .checkbox {
      background-color: $formds-altibox-confirm;
    }
  }
}
