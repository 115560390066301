@import 'src/variables';
@import 'src/app/component-library-wave/designtokens';

.form {
  margin: 0 $spacing * 2;

  .formSection {
    &:first-of-type {
      padding-bottom: $spacing * 4;
    }

    &:last-of-type {
      padding-top: $spacing * 2;
    }

    &:not(:first-of-type, :last-of-type) {
      padding-top: $spacing * 2;
      padding-bottom: $spacing * 4;
    }

    @media (min-width: $screen-sm) {
      &:first-of-type {
        padding-bottom: $spacing * 5;
      }

      &:last-of-type {
        padding: $spacing * 3 0;
      }

      &:not(:first-of-type, :last-of-type) {
        padding-top: $spacing * 3;
        padding-bottom: $spacing * 5;
      }
    }
  }
}

.iconMessage {
  align-items: flex-start;
}

.sectionTitle {
  margin-bottom: $spacing * 2;
}

.formRow:not(:last-of-type) {
  margin-bottom: $spacing * 3;
}

.name {
  margin-bottom: $spacing * 2;
}

.hint {
  margin-bottom: $spacing * 4;
}

.dateOfBirth {
  .datepicker {
    max-width: 18.125rem;
  }

  .errorMessages {
    margin-top: 0;
    margin-bottom: $spacing * 3;
  }
}

.optional {
  margin-left: $spacing * 2;
}

.phoneNumbersRow {
  @media (min-width: $screen-sm) {
    display: flex;
    gap: $spacing * 3;

    .formRow {
      margin-bottom: 0;
      width: 50%;
    }
  }
}

$zip-code-width: 4.5625rem;

.zipCityRow {
  .zip {
    width: $zip-code-width;
    margin-bottom: $spacing * 3;
  }

  .zip,
  .city {
    & > div {
      margin-bottom: 0;
    }
  }

  @media (min-width: $screen-sm) {
    display: flex;
    gap: $spacing * 3;

    .zip {
      margin-bottom: 0;
    }

    .city {
      width: calc(100% - #{$spacing} * 3 - #{$zip-code-width});
    }
  }
}

.houseRow {
  .number {
    margin-bottom: $spacing * 3;
  }

  .number,
  .letter {
    input {
      width: 4.5625rem;
    }

    & > div {
      margin-bottom: 0;
    }
  }

  @media (min-width: $screen-sm) {
    display: flex;
    gap: $spacing * 3;

    .number {
      margin-bottom: 0;
    }
  }
}

.buttonRow {
  display: flex;
  justify-content: flex-end;
  margin-top: $spacing * 2;

  @media (min-width: $screen-sm) {
    margin-top: $spacing * 3;
  }

  .saveButton {
    margin-right: $spacing * 2;
    @media (min-width: $screen-sm) {
      margin-right: 0;
    }
  }
}

.alert {
  margin: $spacing * 2;
  @media (min-width: $screen-sm) {
    margin: $spacing * 3 0;
  }
}

.errorLabel {
  color: $attention-700-shade;
}
