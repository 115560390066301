@import 'variables';
@import 'src/app/component-library-wave/designtokens';

.importantHeadingRow {
  display: flex;

  .infoIcon {
    max-width: 26px;
    margin-right: $spacing;
  }

  .importantHeading {
    margin-bottom: $spacing * 4;
  }
}

.borderContainer {
  border-bottom: 1px solid $secondary-200;
  margin-bottom: $spacing * 4;
}

.indentContainer {
  padding: 0 $spacing * 2;

  .importantMessage {
    margin-bottom: $spacing * 2;
  }

  .emailInfoLink {
    margin-bottom: $spacing * 4;
    display: inline-block;
  }
}

.confirmationHeading {
  margin-bottom: $spacing * 3;
}

.confirmAccountTerminationCheckbox {
  margin-right: $spacing;
}

.missingConsentContainer {
  display: none;
  align-items: center;

  .warningIcon {
    min-width: 24px;
    margin-right: $spacing;
  }

  .missingConsentMessage {
    margin-top: $spacing * 3;
  }
}

.showMissingConsent {
  .confirmationHeading,
  .confirmAccountTerminationCheckbox,
  .confirmAccountTerminationLabel {
    color: $attention-700-shade;
  }

  .missingConsentContainer {
    display: flex;
  }
}
