@import 'src/variables';
@import 'src/app/component-library-wave/designtokens';

$page-left-column-margin: $spacing * 4;
$page-right-column-margin: $spacing * 4;

.desktopTwoColumns {
  display: grid;
  margin-bottom: $spacing * 5;
  row-gap: $spacing * 2;
  grid-template-areas:
    'head head'
    'picture picture'
    'text text';
  grid-template-columns: 1fr 1fr;
  @media (min-width: $screen-md) {
    grid-template-areas:
      'head picture'
      'text picture';
    grid-template-columns: [col1-start] 50% [col3-end] 50%;
    grid-template-rows: [col2-start] 33%;
    margin-bottom: $spacing * 3;
    margin-left: $page-left-column-margin;
    gap: 0;
    align-items: center;
  }
}

.imageColumn {
  grid-area: picture;
}

.textColumn {
  grid-area: text;
  place-self: start;
}

.order {
  grid-area: head;
}

.pageTitle {
  font-size: $headline-xxs-font-size;
  @media (min-width: $screen-md) {
    font-size: 36px;
    margin-top: 65px;
  }
}

.imageContainer {
  padding: $spacing * 2;
  border: 1px solid $formds-neutrals-tint-200;
  border-radius: $border-radius;
  @media (min-width: $screen-md) {
    border: 0;
    padding: 0;
  }
}

.moreInfoLink {
  border-bottom: solid;
  text-decoration: none;
}

.linkText {
  font-size: $paragraph-md-font-size;
  @media (min-width: $screen-md) {
    margin-bottom: $spacing * 5;
  }
}

.awoText {
  margin-bottom: $spacing * 2;
  margin-top: $spacing * 2;
  font-size: $headline6-md-font-size;
}
